import * as utils from '../../../utils';
import { __ } from '../../../i18n';

export function entityTypes(organizationType) {
  return {
    STUDENT: {
      name:
        utils.checkIfItIsClientOrSchool(organizationType) === 1
          ? __('Student')
          : __('Client'),
    },
    STAFF: { name: __('Staff') },
    ADMIN: { name: __('Admin') },
  };
}
