import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import { Loader, Tab, Menu, Button, Segment, Image } from 'semantic-ui-react';

import ListView from '../../components/ListView';
import Page from '../../components/Page';
import Input from '../../components/Input';

import MomentItem from '../Moment/Item';

import { __ } from '../../i18n';
import * as utils from '../../utils';

const PAGE_SIZE = 40;

@graphql(gql`query OrganizationMomentsQuery (
  $id: ID!,
  $limit: Int,
  $offset: Int,
  $search: String,
  $orderBy: [OrganizationMomentsOrder],
  $folder: OrganizationMomentsFolder
) {
    node(id: $id) @connection(key: "Organization", filter: ["id"]) {
      ... on Organization {
        id: dbId
       moments(limit: $limit, offset: $offset, search: $search, orderBy: $orderBy, folder: $folder) {
          nodes {
            id: dbId
            ... MomentItemOrganization
          }
          pageInfo {
            hasPreviousPage
            hasNextPage
          }
        }
      }
    }
  }
  ${MomentItem.fragments.organizationMoment}
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    variables: {
      id: ownProps.params.organization_id,
      limit: PAGE_SIZE,
      offset: 0,
      orderBy: { column: 'CREATED', direction: 'DESC' },
      search: ownProps.location.query.search ? decodeURIComponent(ownProps.location.query.search) : '',
      folder: ownProps.location.query.folder || null
    }
  })
})
export default class OrganizationMoments extends Page {

  constructor(props) {
    super(props);

    this.state = {
      search: props.location.query.search || ''
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const search = nextProps.location.query.search ? decodeURIComponent(nextProps.location.query.search) : '';

    if (search !== this.state.search) {
      this.setState({ search });
    }
  }


  renderRow = (moment, key) =>
    <MomentItem
      key={key}
      moment={filter(MomentItem.fragments.organizationMoment, moment)}
      params={this.props.params}
    />;

  onTabChange = (e, data) => {
    const value = data.activeIndex === 0 ? '' : 'DELETED';

    this.onUniqueParameterChange('folder', value);
  }

  render() {
    const { data, location } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Loader active inline="centered" />;

    const { nodes, pageInfo } = data.node.moments;
    const panes = [
      { menuItem: __('Shared') },
      { menuItem: __('Excluded') }
    ];

    return (
      <div id="OrganizationMoments">
        {this.isMobile() && <h2 style={{ paddingLeft: 8, fontSize: '1.9rem', marginBottom: '40px' }}>{__('Moments')}</h2>}
        <Tab
          menu={{ secondary: true, pointing: true, color: 'blue' }}
          panes={panes}
          onTabChange={this.onTabChange}
          activeIndex={location.query.folder === 'DELETED' ? 1 : 0}
          style={{ marginBottom: 16 }}
        />

        <Menu compact secondary style={{ width: '100%' }}>
          <Input
            name="search"
            icon={null}
            placeholder={__('Search by hashtags')}
            value={this.state.search}
            onChange={(e, { value }) => this.setState({ search: value })}
            style={{ width: '100%' }}
            action={{
              icon: 'search',
              basic: true,
              onClick: () => this.onUniqueParameterChange('search', encodeURIComponent(this.state.search))
            }}
          />
        </Menu>

        {
          nodes.length ?
            <ListView
              segment={false}
              selection={false}
              celled={false}
              style={{ marginTop: 24 }}
              renderRow={this.renderRow}
              source={nodes}
              pagination={{
                graphql: true,
                hasNextPage: pageInfo && pageInfo.hasNextPage,
                loading: data.loading
              }}
              onLoadMore={() => this.loadMore('moments')}
            />
            :
            <Segment basic textAlign="center">
              <Image style={{ maxHeight: 400 }} src={utils.asset('/images/ilustracoes-31.png')} centered />
              <div style={{ color: '#9696a0' }}>
                {
                  location.query.folder === 'DELETED' ?
                    __('Ops, your institution has not deleted any moments yet')
                    :
                    __('Ops, your institution has not posted any moments yet :(')
                }
              </div>
            </Segment>
        }

        {this.props.children}
      </div>
    );
  }
}
