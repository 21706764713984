import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link, browserHistory } from 'react-router';
import qs from 'qs';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Button, Loader, Menu, Label, Header, Popup, Breadcrumb, Form, Divider, Grid, Segment, Icon, Statistic, Dimmer, Dropdown } from 'semantic-ui-react';
import { __ } from '../../i18n';
import TableView from '../../components/TableView';
import Input from '../../components/Input';
import Page from '../../components/Page';
import { flatten } from 'lodash';
import { access } from 'fs';

const PAGE_SIZE = 140;
@inject('store')
@graphql(gql`query OrganizationAccessesQuery($id: ID!, $limit: Int, $orderBy: [OrganizationLinksOrder], $search: String) {
  node(id: $id) @connection(key: "Organization", filter: ["id"]) {
    ... on Organization {
      id: dbId
      links (limit: $limit, orderBy: $orderBy, search: $search){
        nodes {
          id
          dbId
          icon
          title
          url
          type
          organizationId
          replaces
          created
          modified
          deleted
          key
          selected
          highlighted
          expanded
          loaded
          loading
        }
        __typename
      }
      __typename
    }
    __typename
  }
}`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    // fetchPolicy: 'cache-and-network',
    fetchPolicy: ownProps.location.query.forceFetch ? 'network-only' : 'cache-first',
    variables: {
      id: ownProps.params.organization_id,
      limit: PAGE_SIZE,
      search: ownProps.location.query.search || '',
      orderBy: (ownProps.location.query.orderBy && JSON.parse(ownProps.location.query.orderBy)) || { column: 'CREATED', direction: 'DESC' }
    }
  })
})
@observer

export default class OrganizationAccesses extends Page {
  componentDidMount() {
    try {
      if (hj && this.props.store.currentOrganization.plan === 'FREE') {
        hj('trigger', 'gruposfree');
      }
    } catch (e) {
      console.error(e);
    }
  }

  sortOptions = [
    { text: __('Created'), value: '{"column":"CREATED","direction":"DESC"}' },
    // { text: __('Last Update'), value: '{"column":"MODIFIED","direction":"DESC"}' },
    { text: __('Title A-Z'), value: '{"column":"TITLE","direction":"ASC"}' },
    { text: __('Title Z-A'), value: '{"column":"TITLE","direction":"DESC"}' },
    { text: __('Type A-Z'), value: '{"column":"TYPE","direction":"ASC"}' },
    { text: __('Type Z-A'), value: '{"column":"TYPE","direction":"DESC"}' }
  ]

  accessType = {
    URL: __('URL'),
    INTEGRATION: __('Integration'),
    PORTAL: __('Integration')
  }

  renderColumns = [
    __('Icon'),
    __('Accesses'),
    __('Address'),
    __('Type'),
    this.props.store.currentEntity.type === 'ADMIN' || (this.props.store.currentEntity.organization && this.props.store.currentEntity.organization.permissions && (this.props.store.currentEntity.organization.permissions.hasAdmin || this.props.store.currentEntity.organization.permissions.entityScopes.includes('CREATE_UPDATE_LINK') || this.props.store.currentEntity.organization.permissions.entityScopes.includes('DELETE_LINK'))) ?
      __('Action') : null
  ]

  renderHeader = () =>
    <div>
      <Header as="h5" style={{ textAlign: 'left', fontSize: '.9rem' }}>
        <Header.Content>
          <Icon name="box open full" color="blue" style={{ margin: '0px 14.5px 0px 0px' }} />
          {__('Accesses').toUpperCase()}
          <Popup
            trigger={<Icon name="info circle" color="grey" style={{ opacity: '0.6', margin: '0px 5px' }} />}
            content={__('Manage links that your school is adding for students and families to access through ClassApp. Use + Add Access to insert new links.')}
            basic
          />
        </Header.Content>
      </Header>
      <Divider hidden />
    </div>

  renderRowCells = (accesses) => {
    const { params, store } = this.props;


    const hasPermissCreateUpdate = accesses.type === 'URL' && ((store.currentEntity && store.currentEntity.type === 'ADMIN') ||
      (store.currentEntity && store.currentEntity.organization && store.currentEntity.organization.permissions &&
        (store.currentEntity.organization.permissions.hasAdmin || store.currentEntity.organization.permissions.entityScopes.includes('CREATE_UPDATE_LINK'))));

    const hasPermissDelete = accesses.type === 'URL' && ((store.currentEntity && store.currentEntity.type === 'ADMIN') ||
      (store.currentEntity && store.currentEntity.organization && store.currentEntity.organization.permissions &&
        (store.currentEntity.organization.permissions.hasAdmin || store.currentEntity.organization.permissions.entityScopes.includes('DELETE_LINK'))));

    return [
      <Icon name={accesses.icon} color="grey" style={{ margin: '0px 5px' }} />,
      <text style={{ wordBreak: 'break-all', maxWidth: '100%', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2, overflow: 'hidden', textOverflow: 'ellipsis' }}>{accesses.title}</text>,
      <text style={{ wordBreak: 'break-all', maxWidth: '100%', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 3, overflow: 'hidden', textOverflow: 'ellipsis' }}>{accesses.url}</text>,
      this.accessType[accesses.type],
      (hasPermissCreateUpdate || hasPermissDelete) ?
        (<Button.Group basic>
          {(hasPermissCreateUpdate) ?
            <Popup
              trigger={<Button as={Link} to={`/organizations/${params.organization_id}/accesses/${accesses.dbId}/edit`} icon="pencil" />}
              content={__('Edit')}
            />
            : null}
          {
            (hasPermissDelete) ?
              <Popup
                trigger={<Button as={Link} to={`/organizations/${params.organization_id}/accesses/${accesses.dbId}/delete`} icon="trash" />}
                content={__('Delete')}
              />
              : null
          }
        </Button.Group>) : (<Button.Group basic />)
    ];
  }

  render() {
    const { data, store } = this.props;
    if ((data.loading && !data.node) || !data.node) return <Loader active inline="centered" />;
    const links = data.node.links.nodes;

    const hasPermission = ((store.currentEntity && store.currentEntity.type === 'ADMIN') ||
      (store.currentEntity && store.currentEntity.organization && store.currentEntity.organization.permissions &&
        (store.currentEntity.organization.permissions.hasAdmin || store.currentEntity.organization.permissions.entityScopes.includes('CREATE_UPDATE_LINK'))));

    return (
      <div id="OrganizationAccesses">
        {this.renderHeader()}
        <Menu compact>
          <Dropdown
            item
            inline
            text={__('Sort by')}
            value={this.props.location.query.orderBy || '{"column":"CREATED","direction":"DESC"}'}
            options={this.sortOptions}
            onChange={(e, { value }) => this.onUniqueParameterChange('orderBy', value)}
          />
        </Menu>
        {
          hasPermission ? [
            <Menu compact floated="right">
              <Menu.Item
                className="bold"
                data-action="open-access-add"
                content={!this.isMobile() ? __('Add Access') : null}
                icon="plus"
                as={Link}
                to={`/organizations/${this.props.params.organization_id}/accesses/add`}
              />
            </Menu>
          ] : null
        }
        <div style={{ marginTop: '15px', overflowX: 'auto' }}>
          <TableView
            renderColumns={this.renderColumns}
            renderRowCells={this.renderRowCells}
            source={links}
            onLoadMore={this.eventReload}
          />
        </div>
        {this.props.children}
      </div>
    );
  }
}
