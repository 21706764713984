import { getLanguageOptions } from '../getLanguageOptions';

describe('getLanguageOptions', () => {
  it('should return an array of language options', () => {
    const options = getLanguageOptions();
    expect(options).toEqual([
      { text: 'English', value: 'en', 'data-value': 'en' },
      { text: 'Spanish', value: 'es', 'data-value': 'es' },
      { text: 'Portuguese', value: 'pt', 'data-value': 'pt' },
    ]);
  });
});
