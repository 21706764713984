import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Loader } from 'semantic-ui-react';

import { defaultRules } from './Constants';

import Controller from '../../components/Controller';
import OrganizationForm from '../Organization/Form';

import { __ } from '../../i18n';

@inject('store')
@graphql(gql`query OrganizationAddQuery {
  channels {
    nodes {
      id: dbId
      fullname
      picture {
        id: dbId
        uri
      }
    }
  }
}
`, {
  skip: ownProps => !ownProps.store.currentUser.isMaster
})
@graphql(gql`mutation createOrganization($createOrganizationMutation: CreateOrganizationInput!) {
  createOrganization(input: $createOrganizationMutation) {
    organization {
      id: dbId
    }
    clientMutationId
  }
}`, {
  name: 'createOrganization',
  options: {
    refetchQueries: ['AdminOrganizationsQuery']
  }
})
@graphql(gql`mutation createRule($createRule: CreateRuleInput!) {
    createRule(input: $createRule) {
      clientMutationId
    }
  }`, {
  name: 'createRule',
  options: {
    refetchQueries: ['AdminOrganizationsQuery']
  }
}
)
@observer
export default class OrganizationAdd extends Controller {
  static fetchData({ store }) {
    store.app.title = __('Add organization');
  }

  request = (values) => {
    const { params, createRule, createOrganization } = this.props;
    return createOrganization({
      variables: {
        createOrganizationMutation: {
          ...values,
          labels: undefined,
          collectionRule: undefined
        }
      }
    }).then(async (data) => {
      if (values.confRecurrence) {
        await Promise.all(defaultRules(data.data.createOrganization.organization.id).map(rule =>
          new Promise(async (resolve) => {
            const createResponse = await createRule({
              variables: {
                createRule: {
                  organizationId: rule.organizationId,
                  value: rule.value,
                  dueDateInterval: rule.dueDateInterval,
                  interval: rule.interval
                }
              }
            });
            resolve();
          })));
      }
      return data;
    }).then((data) => {
      this.props.store.snackbar = { active: true, message: __('%s was added', values.fullname), success: true };
      this.props.router.push(`/organizations/${data.data.createOrganization.organization.id}/entities`);
    });
  }
  render() {
    const { data, store } = this.props;

    if (store.currentUser.isMaster && ((data.loading && !data.channels) || !data.channels)) return <Loader active />;

    let channels = [];

    if (data && data.channels) {
      channels = data.channels.nodes.map(node => ({
        key: node.id,
        text: node.fullname,
        value: node.id.toString(),
        image: { avatar: true, src: node.picture && node.picture.uri },
      }));

      channels = channels.concat({
        key: null,
        text: __('No consultant'),
        value: null
      });
    }

    return (
      <Controller
        id="OrganizationAdd"
        modal={{ size: 'small' }}
        add
        title={__('Add organization')}
        form={OrganizationForm}
        formProps={{
          user: {
            isMaster: this.props.store.currentUser.isMaster
          },
          channels
        }}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}
