import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link, browserHistory } from 'react-router';
import qs from 'qs';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Menu, Icon, Button, Popup, Dropdown, Form, Loader } from 'semantic-ui-react';
import { __ } from '../../i18n';
import TableView from '../../components/TableView';
import Input from '../../components/Input';
import Page from '../../components/Page';
import BetaModal from '../../components/ui/Modal';

const PAGE_SIZE = 40;

@inject('store')
@graphql(gql`query OrganizationGroupsQuery ($id: ID!, $limit: Int, $offset: Int, $search: String, $orderBy: [OrganizationGroupsOrder]) {
    node(id: $id) @connection(key: "Organization", filter: ["id"]) {
      ... on Organization {
        id: dbId
       groups(limit: $limit, offset: $offset, search: $search, orderBy: $orderBy) {
         totalCount
          nodes {
            name
            id: dbId
            internal
            see_all: seeAll
            entities {
              totalCount
            }
          }
        }
      }
    }
  }
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
    id: ownProps.params.organization_id,
    limit: parseInt(ownProps.location.query.limit || PAGE_SIZE, 10),
    offset: parseInt(((ownProps.location.query.p || 1) - 1) * (ownProps.location.query.limit || PAGE_SIZE), 10),
    search: ownProps.location.query.search || '',
    orderBy: (ownProps.location.query.orderBy && JSON.parse(ownProps.location.query.orderBy)) || { column: 'CREATED', direction: 'DESC' }
    }
    })
  })
@observer
export default class OrganizationGroups extends Page {
  componentDidMount() {
    try {
      if (hj && this.props.store.currentOrganization.plan === 'FREE') {
        hj('trigger', 'gruposfree');
      }
    } catch (e) {
      console.error(e);
    }
  }

  sortOptions = [
    { text: __('Created'), value: '{"column":"CREATED","direction":"DESC"}' },
    { text: __('Name A-Z'), value: '{"column":"NAME","direction":"ASC"}' },
    { text: __('Name Z-A'), value: '{"column":"NAME","direction":"DESC"}' },
    // { text: __('Internal'), value: 'internal.desc' },
    // { text: __('Visible'), value: 'see_all.desc' }
  ]

  renderColumns = [
    __('Groups'),
    __('Visibility'),
    __('People'),
    this.props.store.currentEntity.type === 'ADMIN' || (this.props.store.currentEntity.organization && this.props.store.currentEntity.organization.permissions && (this.props.store.currentEntity.organization.permissions.hasAdmin || this.props.store.currentEntity.organization.permissions.entityScopes.includes('CREATE_UPDATE_GROUP') || this.props.store.currentEntity.organization.permissions.entityScopes.includes('DELETE_GROUP'))) ?
      __('Action') : null
  ]

  renderRowCells = (group) => {
    const { params } = this.props;
    return [
      <Link
        className="groupItem bold defaultClspColor wordWrapped"
        title={group.name}
        data-id={group.id}
        to={`/organizations/${params.organization_id}/groups/${group.id}`}
      >{(group.name.length > 80) ? (group.name.substring(0, 80) + '...') : group.name}</Link>,
      [
        group.internal ?
          <Popup
            trigger={<Button data-title="internal" basic style={{ cursor: 'default' }} icon="messages" />}
            content={__('For internal communication')}
          />
          :
          null,
        group.see_all ?
          <Popup
            trigger={<Button data-title="see_all" basic style={{ cursor: 'default' }} icon="eye" />}
            content={__('Visible to all staff')}
          />
          :
          null
      ],
      <Button.Group basic>
        <Popup
          trigger={<Button style={{ cursor: 'default' }} content={group.entities.totalCount || 0} />}
          content={__('Number of people in group')}
        />
        {
          (this.props.store.currentEntity.type === 'ADMIN' || (this.props.store.currentEntity.organization && this.props.store.currentEntity.organization.permissions && (this.props.store.currentEntity.organization.permissions.hasAdmin || this.props.store.currentEntity.organization.permissions.entityScopes.includes('CREATE_UPDATE_GROUP')))) &&
          (
            <Popup
              trigger={<Button as={Link} to={`/organizations/${params.organization_id}/groups/${group.id}/roles/add`} icon="user plus" />}
              content={__('Add people')}
            />
          )
        }
      </Button.Group>,
      <Button.Group basic>
        {
          (this.props.store.currentEntity.type === 'ADMIN' || (this.props.store.currentEntity.organization && this.props.store.currentEntity.organization.permissions && (this.props.store.currentEntity.organization.permissions.hasAdmin || this.props.store.currentEntity.organization.permissions.entityScopes.includes('CREATE_UPDATE_GROUP')))) &&
          (
            <Popup
              trigger={<Button as={Link} to={`/organizations/${params.organization_id}/groups/${group.id}/edit`} icon="pencil" />}
              content={__('Edit')}
            />
          )
        }
        {
          (this.props.store.currentEntity.type === 'ADMIN' || (this.props.store.currentEntity.organization && this.props.store.currentEntity.organization.permissions && (this.props.store.currentEntity.organization.permissions.hasAdmin || this.props.store.currentEntity.organization.permissions.entityScopes.includes('DELETE_GROUP')))) &&
          (
            <Popup
              trigger={<Button as={Link} to={`/organizations/${params.organization_id}/groups/${group.id}/delete`} icon="trash" />}
              content={__('Delete')}
            />
          )
        }
      </Button.Group>
    ];
  }

  renderInfosModal = () => this.props.store.appends.push(
    <BetaModal
      id="InfoModal"
      onClose={() => this.props.store.appends.pop()}
      size="large"
      closeOnBack
      style={{ width: 903, marginLeft: -451 }}
      header={
        <span>{__('Learn to Create Groups')}<Icon name="graduation cap" style={{ fontWeight: 300, marginLeft: 12 }} /></span>
      }
      content={
        <div>
          <div style={{ marginBottom: 20 }}>
            <p>{__('Watch the video and discover everything you can do in the Groups menu.')}</p>
            <p>
              {__('If you prefer, open it on Youtube to continue watching it while using it. ')}
              <a href="https://youtu.be/0NvR9gGosnk" target="_blank" rel="noopener noreferrer" style={{ color: '#094fff' }}>
                {__('Youtube')}
                <Icon name="square arrow up right" />
              </a>
            </p>
          </div>
          <iframe
            style={{ borderRadius: 12 }}
            width="855"
            height="481"
            src="https://www.youtube.com/embed/0NvR9gGosnk"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
            allowFullScreen
          />
        </div>
      }
    />
  );

  render() {
    const { data, location, store } = this.props;
    const {  unleash } = store;

  const hasNetworkCommunication = (unleash && unleash.isReady && unleash.getStatus('network_communication'))

  if ((data.loading && !data.node) || !data.node) return <Loader active inline="centered" />;

    const groups = data.node.groups;
    const { nodes, totalCount } = groups;
    const isFreeOrganization = store.currentOrganization && store.currentOrganization.plan === 'FREE';

    return (
      <div id="OrganizationGroups">
        <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 40, alignItems: 'center' }}>
          <h2 style={{ paddingLeft: 8, fontSize: '2.29rem', lineHeight: '38px', paddingBottom: '3px' }}>{__('Groups')}</h2>
          {
            !this.isMobile() && isFreeOrganization &&
            <div
              style={{ cursor: 'pointer', marginLeft: '11px', display: 'flex' }}
              onClick={() => this.renderInfosModal()}
            >
              <Icon style={{ fontWeight: 300, color: 'rgba(0, 0, 0, 0.24)', fontSize: '20px' }} name="play circle" />
              <span style={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.4)' }}>{__('See how to use')}</span>
            </div>
          }
        </div>
        <Menu compact secondary>
          <Menu.Item style={{ padding: '0' }}>
            <Menu compact>
              <Menu.Item>
                <Dropdown
                  inline
                  text={__('Sort by')}
                  options={this.sortOptions}
                  value={location.query.orderBy || '{"column":"CREATED","direction":"DESC"}'}
                  onChange={(e, { value }) => this.onUniqueParameterChange('orderBy', value)}
                />
              </Menu.Item>
            </Menu>
          </Menu.Item>
          <Menu.Item style={{ padding: '0' }}>
            <Form.Field
              control={Input}
              wait
              name="search"
              icon="search"
              placeholder={__('Group')}
              onChange={(e, { value }) => this.onUniqueParameterChange('search', value)}
            />
          </Menu.Item>
        </Menu>
        {
          (store.currentEntity.type === 'ADMIN' || (store.currentEntity.organization && store.currentEntity.organization.permissions && (store.currentEntity.organization.permissions.hasAdmin || store.currentEntity.organization.permissions.entityScopes.includes('CREATE_UPDATE_GROUP')))) &&
          (
            <Menu floated="right" compact>
              <Menu.Item
                className="bold"
                data-action="open-group-add"
                as={Link}
                to={`/organizations/${this.props.params.organization_id}/groups/add`}
                icon="plus"
                content={__('Add Group')}
              />
            </Menu>
          )
        }

        <TableView
          renderColumns={this.renderColumns}
          renderRowCells={this.renderRowCells}
          source={nodes}
          pagination={{
            loading: data.loading,
            totalCount,
            query: location.query
          }}
          onLoadMore={this.eventReload}
        />
        {this.props.children}
      </div>
    );
  }
}
