import React from 'react';
import { Link } from 'react-router';
import { Button, Icon, Popup, Menu, Loader, Label, Dropdown, Table, Segment, Image } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { inject, observer } from 'mobx-react';

import { __ } from '../../i18n';
import * as utils from '../../utils';

import Page from '../../components/Page';
import TableView from '../../components/TableView';

const PAGE_SIZE = 40;

const reportQuery = gql`
  query ReportQuery($id: ID!) {
  node (id: $id) {
    ... on Report {
      description
      fields (limit: 400) {
        nodes {
          name
          type
          initial
          options
          position
        }
      }
    }
  }
}`;

@inject('client', 'store')
@graphql(gql`query OrganizationReportsQuery($id: ID!, $limit: Int, $offset: Int) {
    node(id: $id) @connection(key: "Organization", filter: ["id"]) {
      ... on Organization {
        id: dbId
        reports (limit: $limit, offset: $offset) {
          totalCount
          nodes {
            name
            id: dbId
            groups (limit: 400) {
              nodes {
                id: dbId
                name
              }
            }
          }
        }
      }
    }
  }
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      id: ownProps.params.organization_id,
      limit: parseInt(ownProps.location.query.limit || PAGE_SIZE, 10),
      offset: parseInt(((ownProps.location.query.p || 1) - 1) * (ownProps.location.query.limit || PAGE_SIZE), 10),
    }
  })
})
@graphql(gql`mutation createReport($createReportMutation: CreateReportInput!) {
  createReport(input: $createReportMutation) {
    report {
      id: dbId
    }
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['ReportResultFormQuery', 'OrganizationReportsQuery']
  }
})
@observer
export default class OrganizationReports extends Page {
  state = {
    dialog: '',
    reportName: '',
    report_id: null,
    loading: false
  };

  renderColumns = [
    __('Report name'),
    __('Privacy'),
    null
  ]

  onReportDuplicate = async (report) => {
    this.setState({ loading: true });

    const { client, params, mutate } = this.props;
    const { id, groups } = report;

    const response = await client.query({
      query: reportQuery,
      fetchPolicy: 'network-only',
      variables: {
        id
      },
    });

    const values = {};

    values.organizationId = params.organization_id;
    values.name = __('Copy of ') + report.name;
    values.description = response.data.node.description;
    values.groups = groups.nodes.map(group => ({ id: group.id }));
    values.fields = response.data.node.fields.nodes.map((f) => {
      const field = { ...f };

      field.initial = utils.formatReportFieldInitial({
        type: field.type,
        initial: field.initial,
      });

      delete field.__typename;

      return { ...field };
    });

    await mutate({
      variables: {
        createReportMutation: {
          ...values
        }
      }
    });

    this.setState({ loading: false });
  }

  renderRows = (report, i) => <Table.Row key={i} data-id={report.id || report.code} style={{ height: '63px' }} >
    <Table.Cell key={0}>
      <Link
        to={`/organizations/${this.props.params.organization_id}/reports/${report.id}`}
        className="bold defaultClspColor wordWrapped"
      >
        {(report.name.length > 100) ? (report.name.substring(0, 100) + '...') : report.name}
      </Link>
    </Table.Cell>
    <Table.Cell key={1}>
      {
        !report.groups.nodes.length ?
          __('All')
          :
          <div>
            {
              report.groups.nodes.length < 3 ?
                report.groups.nodes.map(group => ((group.name.length > 40) ? (group.name.substring(0, 40) + '...') : group.name)).join(', ')
                :
                [
                  ((report.groups.nodes[0].name.length > 40) ? (report.groups.nodes[0].name.substring(0, 40) + '...') : report.groups.nodes[0].name) + ', ',
                  ((report.groups.nodes[1].name.length > 40) ? (report.groups.nodes[1].name.substring(0, 40) + '...') : report.groups.nodes[1].name),
                  <Popup
                    basic
                    on="click"
                    key={1}
                    content={<div>{report.groups.nodes.slice(2).map(group => <div style={{ padding: '5px' }}>{group.name}</div>)}</div>}
                    trigger={<Label circular as={Button} style={{ marginLeft: '10px' }}>+{report.groups.nodes.length - 2}</Label>}
                  />
                ]
            }
          </div>
      }
    </Table.Cell>
    <Table.Cell key={2} textAlign="right">
      {
        (this.props.store.currentEntity.type === 'ADMIN' || (this.props.store.currentEntity.organization && this.props.store.currentEntity.organization.permissions && (this.props.store.currentEntity.organization.permissions.hasAdmin || this.props.store.currentEntity.organization.permissions.entityScopes.includes('CREATE_UPDATE_REPORT') || this.props.store.currentEntity.organization.permissions.entityScopes.includes('DELETE_REPORT')))) &&
        (
          <Dropdown icon="ellipsis v" data-action="open-report-options" style={{ border: 'none', boxShadow: 'none', color: '#a0a0a0' }}>
            <Dropdown.Menu style={this.isMobile() ? { left: 'unset', right: 0 } : {}}>
              {
                (this.props.store.currentEntity.type === 'ADMIN' || (this.props.store.currentEntity.organization && this.props.store.currentEntity.organization.permissions && (this.props.store.currentEntity.organization.permissions.hasAdmin || this.props.store.currentEntity.organization.permissions.entityScopes.includes('CREATE_UPDATE_REPORT')))) &&
                [
                  <Dropdown.Item as={Link} to={`/organizations/${this.props.params.organization_id}/reports/${report.id}/edit`}>
                    <Icon name="pencil" />{__('Edit')}
                  </Dropdown.Item>,
                  <Dropdown.Item onClick={() => this.onReportDuplicate(report)}>
                    <Icon name="clone" />{__('Duplicate')}
                  </Dropdown.Item>
                ]
              }
              {
                (this.props.store.currentEntity.type === 'ADMIN' || (this.props.store.currentEntity.organization && this.props.store.currentEntity.organization.permissions && (this.props.store.currentEntity.organization.permissions.hasAdmin || this.props.store.currentEntity.organization.permissions.entityScopes.includes('DELETE_REPORT')))) &&
                (
                  <Dropdown.Item as={Link} to={`/organizations/${this.props.params.organization_id}/reports/${report.id}/delete`}>
                    <Icon name="trash" />{__('Delete')}
                  </Dropdown.Item>
                )
              }
            </Dropdown.Menu>
          </Dropdown>
        )
      }
    </Table.Cell>
  </Table.Row>

  render() {
    const { params, data, location, store } = this.props;
    const { loading } = this.state;

    if ((data.loading && !data.node) || !data.node || loading) return <Loader id="OrganizationReports" active inline="centered" />;

    const { reports } = data.node;
    const { nodes, totalCount } = reports;

    return (
      <div id="OrganizationReports">
        {
          (store.currentEntity.type === 'ADMIN' || (store.currentEntity.organization && store.currentEntity.organization.permissions && (store.currentEntity.organization.permissions.hasAdmin || store.currentEntity.organization.permissions.entityScopes.includes('CREATE_UPDATE_REPORT')))) &&
          [
            <Menu compact secondary />,
            <Menu compact floated="right">
              <Menu.Item className="bold" as={Link} to={`/organizations/${params.organization_id}/reports/add`}>
                <Icon name="plus" />
                {__('Add Report')}
              </Menu.Item>
            </Menu>
          ]
        }
        {
          nodes.length ?
            <TableView
              renderColumns={this.renderColumns}
              renderRows={this.renderRows}
              source={nodes}
              pagination={{
                loading: data.loading,
                totalCount,
                query: location.query,
                basic: true
              }}
              onLoadMore={this.eventReload}
            />
            :
            <Segment basic textAlign="center">
              <Image style={{ maxHeight: 400 }} src={utils.asset('/images/ilustracoes-43.png')} centered />
              <div style={{ color: '#9696a0' }}>{__('There is no report, ask the admin')}</div>
            </Segment>
        }
        {this.props.children}
      </div>
    );
  }
}
