import { getCountryOptions } from '../getCountryOptions';

describe('getCountryOptions', () => {
  it('should return an array of country options', () => {
    const options = getCountryOptions();
    expect(options).toEqual([
      { text: 'Brasil', value: 'br', 'data-value': 'br' },
      { text: 'United States', value: 'us', 'data-value': 'us' },
    ]);
  });
});
