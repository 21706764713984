/*eslint prefer-const: off*/
import React from 'react';
import { browserHistory } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Container, Dropdown, Divider, Grid, Icon, Popup, Menu } from 'semantic-ui-react';
import qs from 'qs';

import { __ } from '../../i18n';
import * as utils from '../../utils';

import Responsive from '../../components/Responsive';
import { limitOptions } from './helpers/getLimitOptions';

import MessageLimitItem from '../Dashboard/MessageLimit/Item';
import ReadingRateItem from '../Dashboard/ReadingRate/Item';
import DashboardAdhesionItem from '../Dashboard/Adhesion/Item';
import DashboardSentItem from '../Dashboard/Sent/Item';
import DashboardReceivedItem from '../Dashboard/Received/Item';
import DashboardRepliesItem from '../Dashboard/Replies/Item';

// import DashboardChannelsItem from '../Dashboard/Channels/Item';

@inject('store') @observer
export default class OrganizationDashboard extends Responsive {
  state = {
    parameters: {}
  };

  // Runs after fetchData
  componentDidMount() {
    let { location } = this.props;

    this.setState({ parameters: { ...location.query, limit: location.query.limit && parseInt(location.query.limit) } });
  }

  componentWillUnmount() {
    this.props.store.dashboard = null;
  }

  onParameterChange = (name, value) => {
    const parameters = { ...this.state.parameters };

    if (!value || value === 'all') {
      delete parameters[name];
    } else {
      parameters[name] = value;
    }

    this.setState({ parameters }, () => {
      this.eventReload();
    });
  }

  eventReload = () => {
    const parameters = this.state.parameters;
    const params = qs.stringify(parameters);
    const pathname = this.props.location.pathname;

    if (params.length > 0) {
      browserHistory.push(pathname + '?' + params);
    } else {
      browserHistory.push(pathname);
    }
  }

  renderMobile = () => {
    const { store } = this.props;

    const { parameters } = this.state;
    const lang = store.app.locale ? (store.app.locale === 'pt' ? 'pt-BR' : store.app.locale) : 'en';
    const date = new Date();
    const now = utils.simpleDate(date, true, 'LL', lang);
    const then = utils.simpleDate(date.setDate(date.getDate() - (parameters.limit - 1 || 30)), true, 'LL', lang);

    return (
      <div style={{ padding: '0 5px' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <span style={{ fontSize: '22px', fontWeight: 'bold' }}>{__('Dashboard')}</span>
        </div>
        <div>
          <div>
            <div style={{ display: 'flex', marginTop: '15px' }}>
              <MessageLimitItem isMobile={this.isMobile()} {...this.props} />
            </div>
            <div style={{ display: 'flex', marginTop: '17px' }}>
              <ReadingRateItem isMobile={this.isMobile()} {...this.props} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', marginBottom: '10px', height: '7rem' }}>
              <div style={{ display: 'flex', flexDirection: 'column', width: '60%' }}>
                <span style={{ opacity: '0.4', fontSize: '13px' }}>
                  {__('Period from %s to %s', then, now)}
                </span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: '150px' }}>
                <span style={{ fontWeight: 600 }}>{__('Filter by:')}</span>
                <Dropdown
                  data-action="select-period"
                  options={limitOptions()}
                  selection
                  style={{ borderRadius: '20px', minWidth: '150px' }}
                  value={parameters.limit || 30}
                  onChange={(e, { value }) => this.onParameterChange('limit', value)}
                />
              </div>
            </div>
            <div>
              <DashboardAdhesionItem
                {...this.props}
                isMobile={this.isMobile()}
                onParameterChange={this.onParameterChange}
                parameters={parameters}
              />
            </div>
            <div style={{ marginTop: '10px' }}>
              <DashboardReceivedItem isMobile={this.isMobile()} {...this.props} />
            </div>
            <div style={{ marginTop: '10px' }}>
              <DashboardSentItem isMobile={this.isMobile()} {...this.props} />
            </div>
            <div style={{ marginTop: '10px' }}>
              <DashboardRepliesItem isMobile={this.isMobile()} {...this.props} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderDesktop = () => {
    const { store } = this.props;

    const { parameters } = this.state;
    const lang = store.app.locale ? (store.app.locale === 'pt' ? 'pt-BR' : store.app.locale) : 'en';
    const date = new Date();
    const now = utils.simpleDate(date, true, 'LL', lang);
    const then = utils.simpleDate(date.setDate(date.getDate() - (parameters.limit || 30)), true, 'LL', lang);

    return (
      <div>
        <Container key={0} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
          <span style={{ fontSize: '22px', fontWeight: 'bold' }}>{__('Dashboard')}</span>
        </Container>
        <Divider hidden />
        <Container style={{ width: '975px' }}>
          <Grid container>
            <Grid.Row style={{ display: 'flex' }}>
              <MessageLimitItem {...this.props} />
              <div style={{ flex: 1, marginLeft: '10px' }}>
                <ReadingRateItem {...this.props} />
              </div>
            </Grid.Row>
            <Container key={0} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', marginBottom: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ opacity: '0.5', marginTop: '15px' }}>
                  {__('Period from %s to %s', then, now)}
                </div>
              </div>
              <div>
                <strong>{__('Filter by:')}</strong>
                <span style={{ marginLeft: '10px' }}>
                  <Dropdown
                    data-action="select-period"
                    options={limitOptions()}
                    selection
                    style={{ borderRadius: '20px' }}
                    value={parameters.limit || 30}
                    onChange={(e, { value }) => this.onParameterChange('limit', value)}
                  />
                </span>
              </div>
              {/*<Button content={__('Print')} />*/}
            </Container>
            <Grid.Row>
              <DashboardAdhesionItem
                {...this.props}
                onParameterChange={this.onParameterChange}
                parameters={parameters}
              />
            </Grid.Row>
            <Divider hidden />
            <Grid.Row>
              <DashboardReceivedItem {...this.props} />
              <div style={{ flex: 1, marginLeft: '10px' }}>
                <DashboardSentItem {...this.props} />
              </div>
            </Grid.Row>
            <Grid.Row>
              <DashboardRepliesItem {...this.props} />
            </Grid.Row>
            {/*<Grid.Row>
              <DashboardChannelsItem data={filter(DashboardChannelsItem.fragments.organization, data.node)} />
            </Grid.Row>*/}
          </Grid>
        </Container>
      </div>
    );
  }

  render() {
    return (
      <div id="OrganizationDashboard">
        {this.isMobile() ? this.renderMobile() : this.renderDesktop()}
      </div>
    );
  }
}
