import { __ } from '../../../../i18n';

export const getWeekDays = () => [
  { text: __('Sunday'), value: 'Sunday', 'data-value': 'Sunday' },
  { text: __('Monday'), value: 'Monday', 'data-value': 'Monday' },
  { text: __('Tuesday'), value: 'Tuesday', 'data-value': 'Tuesday' },
  { text: __('Wednesday'), value: 'Wednesday', 'data-value': 'Wednesday' },
  { text: __('Thursday'), value: 'Thursday', 'data-value': 'Thursday' },
  { text: __('Friday'), value: 'Friday', 'data-value': 'Friday' },
  { text: __('Saturday'), value: 'Saturday', 'data-value': 'Saturday' },
];
