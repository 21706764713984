import { __ } from '../../../i18n';

export function limitOptions() {
  return [
    // eslint-disable-next-line sonarjs/no-duplicate-string
    { text: __('Last %s days', 30), value: 30 },
    { text: __('Last %s days', 15), value: 15 },
    { text: __('Last %s days', 7), value: 7 },
  ];
}
