import { getWeekDays } from '../getWeekDays';

describe('given the getWeekDays function', () => {
  it('should return the correct week days', () => {
    const result = getWeekDays();
    expect(result).toEqual([
      { text: 'Sunday', value: 'Sunday', 'data-value': 'Sunday' },
      { text: 'Monday', value: 'Monday', 'data-value': 'Monday' },
      { text: 'Tuesday', value: 'Tuesday', 'data-value': 'Tuesday' },
      { text: 'Wednesday', value: 'Wednesday', 'data-value': 'Wednesday' },
      { text: 'Thursday', value: 'Thursday', 'data-value': 'Thursday' },
      { text: 'Friday', value: 'Friday', 'data-value': 'Friday' },
      { text: 'Saturday', value: 'Saturday', 'data-value': 'Saturday' },
    ]);
  });
});
