import { __ } from '../../../../i18n';

export function getTypeOptions() {
  return [
    { text: __('School'), value: 'SCHOOL', 'data-value': 'school' },
    { text: __('Gym'), value: 'GYM', 'data-value': 'gym' },
    { text: __('Clinic'), value: 'CLINIC', 'data-value': 'clinic' },
    { text: __('Company'), value: 'COMPANY', 'data-value': 'company' },
    {
      text: __('Language School'),
      value: 'LANGUAGE_SCHOOL_ALL',
      'data-value': 'language_school_all',
    },
    {
      text: __('Extracurricular'),
      value: 'EXTRACURRICULAR',
      'data-value': 'extracurricular',
    },
    { text: __('Distance Education'), value: 'DE', 'data-value': 'de' },
    { text: __('Ballet'), value: 'BALLET', 'data-value': 'ballet' },
    { text: __('Soccer'), value: 'SOCCER', 'data-value': 'soccer' },
    {
      text: __('Music school'),
      value: 'MUSIC_SCHOOL',
      'data-value': 'music_school',
    },
    {
      text: __('Technical course'),
      value: 'TECHNICAL_COURSE',
      'data-value': 'technical_course',
    },
    { text: __('NGO'), value: 'NGO', 'data-value': 'ngo' },
    { text: __('Code'), value: 'CODE', 'data-value': 'code' },
  ];
}
