import React from 'react';
import { inject, observer } from 'mobx-react';
import { ViewUI as ChannelConfig } from '../../app/Presentation/pages/Channel/Config/ViewUI';
import ChannelList from './Channels';
import Loading from '../../components/ui/Loading';

/**
 * @param {{
 *  store: {
 *    currentOrganization: {
 *      unleashStatus: {
 *        web_menu_conversations: boolean;
 *      },
 *      productFeatures: {
 *        nodes: {
 *          id: number | string;
 *          identifier: string;
 *          isEnabled: boolean;
 *          paid: boolean;
 *          plan: string;
 *        }[],
 *        totalCount: number
 *        } | null;
 *      }
 *    }
 *  channelConfig?: boolean
 *  }} props
 * @returns {JSX.Element}
 */

export function ChannelsSelect(props) {
  const { store, location } = props;
  const { channelConfig } = location?.state || false;

  if (!store.currentOrganization) {
    return (
      <Loading
        width={16}
        height={16}
      />
    );
  }

  return channelConfig
    ? <ChannelConfig {...props} />
    : <ChannelList {...props} />;
}

export default inject('store')(observer(ChannelsSelect));
