export const recurrenceLinks = {
  authClientId: '8357649ef18ac7a6191d06ca7d0873218a61ae5fac2f6104b74796a104102441',
  urlRedirect: {
    local: 'http://localhost:5500',
    stage: 'https://krabs.classapp.ninja/',
    production: 'https://financeiro.classapp.com.br/'
  }
};

export const loginFrom = {
  local: 'http://localhost:5400',
  stage: 'http://classapp.ninja',
  production: 'https://classapp.com.br',
  beta: 'https://beta.classapp.com.br',
  internal: 'http://walle-internal.classapp.ninja'
};

export function defaultRules(id) {
  return [
    {
      organizationId: id,
      value: 10,
      interval: 'DAY',
      dueDateInterval: 'BEFORE'
    },
    {
      organizationId: id,
      value: 5,
      interval: 'DAY',
      dueDateInterval: 'BEFORE'
    },
    {
      organizationId: id,
      value: 0,
      interval: 'DAY',
      dueDateInterval: 'AFTER'
    },
    {
      organizationId: id,
      value: 5,
      interval: 'DAY',
      dueDateInterval: 'AFTER'
    },
    {
      organizationId: id,
      value: 10,
      interval: 'DAY',
      dueDateInterval: 'AFTER'
    }
  ];
}

export const recurrenceApplicationIds = {
  local: 10,
  stage: 30,
  production: 161
};
