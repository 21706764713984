import React from 'react';
import { Link } from 'react-router';
import { Icon, Menu, Loader, Dropdown, Table, Segment, Image } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { inject, observer } from 'mobx-react';

import { __ } from '../../i18n';
import * as utils from '../../utils';

import Page from '../../components/Page';
import TableView from '../../components/TableView';

const PAGE_SIZE = 40;

const formQuery = gql`
  query FormQuery($id: ID!) {
  node (id: $id) {
    ... on Form {
      description
      template
      fields (limit: 400) {
        nodes {
          name
          type
          initial
          options
          position
        }
      }
    }
  }
}`;

@inject('client', 'store')
@graphql(gql`query OrganizationFormsQuery($id: ID!, $limit: Int, $offset: Int) {
    node(id: $id) @connection(key: "Organization", filter: ["id"]) {
      ... on Organization {
        id: dbId
        forms (limit: $limit, offset: $offset, template: true) {
          totalCount
          nodes {
            name
            id: dbId
          }
        }
      }
    }
  }
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      id: ownProps.params.organization_id,
      limit: parseInt(ownProps.location.query.limit || PAGE_SIZE, 10),
      offset: parseInt(((ownProps.location.query.p || 1) - 1) * (ownProps.location.query.limit || PAGE_SIZE), 10),
    }
  })
})
@graphql(gql`mutation createForm($createFormMutation: CreateFormInput!) {
  createForm(input: $createFormMutation) {
    form {
      id: dbId
    }
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['FormResultFormQuery', 'OrganizationFormsQuery']
  }
})
@observer
export default class OrganizationForms extends Page {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  renderColumns = [
    __('Form name'),
    null
  ]

  onFormDuplicate = async (form) => {
    this.setState({ loading: true });

    const { client, params, mutate } = this.props;
    const { id } = form;

    const response = await client.query({
      query: formQuery,
      fetchPolicy: 'network-only',
      variables: {
        id
      },
    });

    const values = {};

    values.organizationId = params.organization_id;
    values.name = __('Copy of ') + form.name;
    values.description = response.data.node.description;
    values.template = response.data.node.template;
    values.fields = response.data.node.fields.nodes.map((f) => {
      const field = { ...f };

      if (field.type === 'SELECT' && field.initial && field.initial.length && field.initial[0] !== null) {
        field.initial = field.initial[0].toString();
      } else if (field.type === 'CHECK' && field.initial && field.initial.length) {
        field.initial = field.initial.map(option => option.toString());
      } else if (field.type === 'CHECK') {
        field.initial = [];
      }

      delete field.__typename;

      return { ...field };
    });

    await mutate({
      variables: {
        createFormMutation: {
          ...values
        }
      }
    });

    this.setState({ loading: false });
  }

  renderRows = (form, i) => <Table.Row key={i} data-id={form.id || form.code} style={{ height: '63px' }} >
    <Table.Cell key={0}>
      <Link
        to={`/organizations/${this.props.params.organization_id}/forms/${form.id}`}
        className="bold defaultClspColor wordWrapped"
      >
        {(form.name.length > 100) ? (form.name.substring(0, 100) + '...') : form.name}
      </Link>
    </Table.Cell>
    <Table.Cell key={2} textAlign="right">
      {
        (this.props.store.currentEntity.type === 'ADMIN' || (this.props.store.currentEntity.organization && this.props.store.currentEntity.organization.permissions && (this.props.store.currentEntity.organization.permissions.hasAdmin || this.props.store.currentEntity.organization.permissions.entityScopes.includes('CREATE_UPDATE_FORM') || this.props.store.currentEntity.organization.permissions.entityScopes.includes('DELETE_FORM')))) &&
        (
          <Dropdown icon="ellipsis v" data-action="open-form-options" style={{ border: 'none', boxShadow: 'none', color: '#a0a0a0' }}>
            <Dropdown.Menu style={this.isMobile() ? { left: 'unset', right: 0 } : {}}>
              {
                (this.props.store.currentEntity.type === 'ADMIN' || (this.props.store.currentEntity.organization && this.props.store.currentEntity.organization.permissions && (this.props.store.currentEntity.organization.permissions.hasAdmin || this.props.store.currentEntity.organization.permissions.entityScopes.includes('CREATE_UPDATE_FORM')))) &&
                [
                  <Dropdown.Item as={Link} to={`/organizations/${this.props.params.organization_id}/forms/${form.id}/edit`}>
                    <Icon name="pencil" />{__('Edit')}
                  </Dropdown.Item>,
                  <Dropdown.Item onClick={() => this.onFormDuplicate(form)}>
                    <Icon name="clone" />{__('Duplicate')}
                  </Dropdown.Item>
                ]
              }
              {
                (this.props.store.currentEntity.type === 'ADMIN' || (this.props.store.currentEntity.organization && this.props.store.currentEntity.organization.permissions && (this.props.store.currentEntity.organization.permissions.hasAdmin || this.props.store.currentEntity.organization.permissions.entityScopes.includes('DELETE_FORM')))) &&
                (
                  <Dropdown.Item as={Link} to={`/organizations/${this.props.params.organization_id}/forms/${form.id}/delete`}>
                    <Icon name="trash" />{__('Delete')}
                  </Dropdown.Item>
                )
              }
            </Dropdown.Menu>
          </Dropdown>
        )
      }
    </Table.Cell>
  </Table.Row>

  render() {
    const { params, data, location, store } = this.props;
    const { loading } = this.state;

    if ((data.loading && !data.node) || !data.node || loading) return <Loader id="OrganizationForms" active inline="centered" />;

    const { forms } = data.node;
    const { nodes, totalCount } = forms;

    return (
      <div id="OrganizationForms">
        {
          (store.currentEntity.type === 'ADMIN' || (store.currentEntity.organization && store.currentEntity.organization.permissions && (store.currentEntity.organization.permissions.hasAdmin || store.currentEntity.organization.permissions.entityScopes.includes('CREATE_UPDATE_FORM')))) &&
          [
            <Menu compact secondary />,
            <Menu compact floated="right">
              <Menu.Item className="bold" as={Link} to={`/organizations/${params.organization_id}/forms/add`}>
                <Icon name="plus" />
                {__('Add Form')}
              </Menu.Item>
            </Menu>
          ]
        }
        {
          nodes.length ?
            <TableView
              renderColumns={this.renderColumns}
              renderRows={this.renderRows}
              source={nodes}
              pagination={{
                loading: data.loading,
                totalCount,
                query: location.query,
                basic: true
              }}
              onLoadMore={this.eventReload}
            />
            :
            <Segment basic textAlign="center">
              <Image style={{ maxHeight: 400 }} src={utils.asset('/images/ilustracoes-43.png')} centered />
              <div style={{ color: '#9696a0' }}>{__('There is no form, ask the admin')}</div>
            </Segment>
        }
        {this.props.children}
      </div>
    );
  }
}
