import { getFunctionalityOptions } from '../getFunctionalityOptions';

describe('given the getFunctionalityOptions function', () => {
  const props = {
    organization: {
      type: 'school',
      features: {
        doNotDisturb: false,
      },
    },
    settings: {
      confMessage: true,
      confReplyStudent: true,
      confMedia: true,
      confRepliesStudent: true,
      ntfStartTime: '08:00',
      ntfEndTime: '20:00',
      ntfWeekDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
      confRecurrence: true,
    },
    props: {
      user: {
        isMaster: true,
        add: true,
      },
    },
    studentRepliesFeature: false,
    isNetwork: false,
  };

  it('should return the functionality options', () => {
    const result = getFunctionalityOptions(props);
    expect(result).toBeDefined();
  });
});
