/* eslint-disable prettier/prettier */
export const getTimeOptions = (has59) => ({
  hour: [
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '00',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
  ].map((v) => ({ text: v, value: v })),
  minute: (has59
    ? [
      '00',
      '05',
      '10',
      '15',
      '20',
      '25',
      '30',
      '35',
      '40',
      '45',
      '50',
      '55',
      '59',
    ]
    : ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55']
  ).map((v) => ({ text: v, value: v })),
});
