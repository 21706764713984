/*eslint prefer-const: off*/
import React from 'react';
import { Tab, Icon } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import Page from '../../components/Page';

import { __ } from '../../i18n';
import { panes as getPanes } from './helpers/getPanes';

@inject('store') @observer
export default class OrganizationCharges extends Page {
  state = {
    reloadTab: 0
  }

  onTabChange = (e, data) => {
    const { router, params, location } = this.props;
    const { reloadTab } = this.state;

    if (data.panes[data.activeIndex].value === location.pathname.split('/')[3]) {
      this.setState({ reloadTab: 1 - reloadTab });
    }

    router.push(`/organizations/${params.organization_id}/${data.panes[data.activeIndex].value}`);
  }

  render() {
    const { reloadTab } = this.state;
    const { location, store } = this.props;
    const tabs = [];
    const panes = getPanes();

    if (store.currentEntity.type === 'ADMIN' ||
      (store.currentEntity.organization && store.currentEntity.organization.permissions &&
        (store.currentEntity.organization.permissions.hasAdmin || store.currentEntity.organization.permissions.entityScopes.includes('READ_PAYMENT')))) {
      tabs.push({ ...panes.find(pane => pane.value === 'payments'), index: tabs.length });
    }
    if (store.currentEntity.type === 'ADMIN' ||
      (store.currentEntity.organization && store.currentEntity.organization.permissions &&
        (store.currentEntity.organization.permissions.hasAdmin || store.currentEntity.organization.permissions.entityScopes.includes('READ_PAYMENT')))) {
      tabs.push({ ...panes.find(pane => pane.value === 'charges'), index: tabs.length });
    }
    if (store.currentEntity.type === 'ADMIN' ||
      (store.currentEntity.organization && store.currentEntity.organization.permissions &&
        (store.currentEntity.organization.permissions.hasAdmin || store.currentEntity.organization.permissions.entityScopes.includes('READ_ACCOUNT')))) {
      tabs.push({ ...panes.find(pane => pane.value === 'accounts'), index: tabs.length });
    }
    return (
      <div id="OrganizationCharges">
        <div style={{ width: '300px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <h1>{__('ClassPay')}</h1>
          <a
            href="https://ajuda.classapp.com.br/hc/pt-br/categories/1260801778450-ClassPay"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'gray', ...(!this.isMobile() ? { position: 'absolute', right: '7rem' } : { marginLeft: '14px' }), alignItems: 'center', display: 'flex' }}
          >
            <Icon name="question circle outline" size="medium" style={{ fontSize: '20px' }} />
            {__('All about ClassPay')}
          </a>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Tab
            menu={{ secondary: true, pointing: true, color: 'blue' }}
            panes={tabs}
            onTabChange={this.onTabChange}
            activeIndex={tabs.length > 0 && tabs.find(pane => location.pathname.indexOf(pane.value) > -1).index}
            style={{ margin: '0px 0px 24px 0px' }}
          />
        </div>
        {React.cloneElement(this.props.children, { reloadTab })}
      </div >
    );
  }
}
