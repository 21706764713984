import { organizationPages } from '../organizationPage';

describe('organizationPages', () => {
  it('should return an object', () => {
    expect(typeof organizationPages({})).toBe('object');
  });

  it('should return an object with all pages', () => {
    const result = organizationPages({
      confCharge: true,
      features: { reports: true, api: true, charges: true },
    });
    expect(result).toEqual({
      READ_ENTITY: ['entities', 'channels'],
      READ_DASHBOARD: ['dashboard'],
      READ_GROUP: ['groups'],
      READ_MESSAGE: ['messages', 'chats'],
      READ_REPORT: ['reports'],
      READ_INTEGRATION: ['integrations'],
      READ_PAYMENT: ['payments', 'charges'],
      READ_FORM: [],
      READ_ACCOUNT: ['accounts'],
      READ_LINK: [],
      UPDATE_ORGANIZATION: ['edit'],
      READ_FILES: [],
    });
  });
});
