import { entityTypes } from '../getEntityTypes';
import * as utils from '../../../../utils';
import { __ } from '../../../../i18n';

jest.mock('../../../../utils');

describe('entityTypes', () => {
  it('should return "Student" for organizationType indicating a student', () => {
    utils.checkIfItIsClientOrSchool.mockReturnValue(1);
    const result = entityTypes('someOrganizationType');
    expect(result.STUDENT.name).toEqual(__('Student'));
  });

  it('should return "Client" for organizationType indicating a client', () => {
    utils.checkIfItIsClientOrSchool.mockReturnValue(2);
    const result = entityTypes('someOrganizationType');
    expect(result.STUDENT.name).toEqual(__('Client'));
  });

  it('should return "Staff" for STAFF type', () => {
    const result = entityTypes('someOrganizationType');
    expect(result.STAFF.name).toEqual(__('Staff'));
  });

  it('should return "Admin" for ADMIN type', () => {
    const result = entityTypes('someOrganizationType');
    expect(result.ADMIN.name).toEqual(__('Admin'));
  });
});
