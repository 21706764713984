import { getOrganizationMenuIcons } from '../getOrganizationMenu';

describe('getOrganizationMenuIcons', () => {
  it('should return all items', async () => {
    const unleash = {
      unleash: {
        toggles: [{ name: 'feature_arquivos' }],
      },
    };

    const store = { currentUser: { isMaster: true } };
    const permissions = {
      hasAdmin: false,
      entityScopes: ['UPDATE_ORGANIZATION'],
    };
    const productFeatures = { nodes: [{ identifier: 'arrived' }] };
    const organization = { id: 'test-organization' };

    const { items } = await getOrganizationMenuIcons({
      store,
      permissions,
      features: {},
      productFeatures,
      confTerms: {},
      confCharge: {},
      organization,
      unleash,
    });

    const expectedItems = [
      {
        id: 'dashboard',
        icon: 'tachometer alt',
        name: 'Dashboard',
        to: `/organizations/${organization.id}/dashboard?type=student`,
        'data-action': 'dashboard',
        toParams: ['type=student'],
      },
      {
        id: 'people',
        icon: 'users',
        name: 'People',
        to: `/organizations/${organization.id}/entities`,
        'data-action': 'entities',
      },
      {
        id: 'channels',
        icon: 'message smile',
        name: 'Channels',
        to: `/organizations/${organization.id}/channels`,
        'data-action': 'channels',
      },
      {
        id: 'groups',
        icon: 'graduation cap',
        name: 'Groups',
        to: `/organizations/${organization.id}/groups`,
        'data-action': 'groups',
      },
    ];

    expectedItems.forEach((expectedItem, index) => {
      expect(items[index]).toEqual(expectedItem);
    });
  });
});
