import React from 'react';
import { Link, browserHistory } from 'react-router';
import {
  Message,
  Input,
  Divider,
  Button,
  Segment,
  Popup,
  Label,
  Container,
  Icon,
} from 'semantic-ui-react';
import { TwitterPicker } from 'react-color';
import _ from 'lodash';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import Switch from 'react-switch';
import { inject, observer } from 'mobx-react';

import Form from '../../../components/Form';
import BetaModal from '../../../components/ui/Modal';
import BetaButton from '../../../components/ui/Button';

import LabelEdit from '../../Label/Edit';
import LabelAdd from '../../Label/Add';

import { __ } from '../../../i18n';
import { zones } from '../../../lib/zones';

import { ArrivedSwitch } from '../components/ArrivedSwitch';

import { isNetwork } from '../../../app/helpers/isNetwork';
import { isNetworkChild } from '../../../app/helpers/isNetworkChild';
import { getLanguageOptions } from './helpers/getLanguageOptions';
import { getCountryOptions } from './helpers/getCountryOptions';
import { getTypeOptions } from './helpers/getTypeOptions';
import { LabelsSection } from '../components/LabelsSection';
import { TagsList } from '../components/TagsList';
import { getFunctionalityOptions } from './helpers/getFunctionalityOptions';

const planOptions = [
  { text: __('Premium'), value: 'PREMIUM', 'data-value': 'premium' },
  { text: __('Standard'), value: 'STANDARD', 'data-value': 'standard' },
  { text: __('Light'), value: 'LIGHT', 'data-value': 'light' },
  { text: __('Free'), value: 'FREE', 'data-value': 'free' },
  { text: __('Light new'), value: 'LIGHT_NEW', 'data-value': 'light_new' },
  {
    text: __('Standard new'),
    value: 'STANDARD_NEW',
    'data-value': 'standard_new',
  },
];

const usageOptions = [
  { text: __('Test'), value: 'TEST', 'data-value': 'test' },
  { text: __('Client'), value: 'CLIENT', 'data-value': 'client' },
  {
    text: __('Former Client'),
    value: 'FORMER_CLIENT',
    'data-value': 'former_client',
  },
  { text: __('Sandbox'), value: 'SANDBOX', 'data-value': 'sandbox' },
  { text: __('Simulation'), value: 'SIMULATION', 'data-value': 'simulation' },
  { text: __('Free Trial'), value: 'TRIAL', 'data-value': 'trial' },
];

const CREATE_ORGANIZATION_BANNER = gql`
  mutation CreateOrganizationBanner(
    $CreateOrganizationBannerInput: CreateOrganizationBannerInput!
  ) {
    createOrganizationBanner(input: $CreateOrganizationBannerInput) {
      organizationBanner {
        id
        organizationId
        bannerType
      }
    }
  }
`;

const DELETE_ORGANIZATION_BANNER = gql`
  mutation DeleteOrganizationBanner(
    $DeleteOrganizationBannerInput: DeleteOrganizationBannerInput!
  ) {
    deleteOrganizationBanner(input: $DeleteOrganizationBannerInput) {
      organizationBanner {
        id
        organizationId
        bannerType
      }
    }
  }
`;

@graphql(
  gql`
    mutation enableProductFeature($id: Int!, $identifier: String!) {
      enableProductFeature(
        input: { identifier: $identifier, organizationId: $id }
      ) {
        enableSuccess
      }
    }
  `,
  {
    options: {
      refetchQueries: [
        'AppQuery',
        'OrganizationNodeIdQuery',
        'OrganizationEditMasterQuery',
      ],
    },
    name: 'enableProductFeatureMutation',
  },
)
@graphql(
  gql`
    mutation disableProductFeature($id: Int!, $identifier: String!) {
      disableProductFeature(
        input: { identifier: $identifier, organizationId: $id }
      ) {
        disableSuccess
      }
    }
  `,
  {
    options: {
      refetchQueries: [
        'AppQuery',
        'OrganizationNodeIdQuery',
        'OrganizationEditMasterQuery',
      ],
    },
    name: 'disableProductFeatureMutation',
  },
)
@inject('store', 'client')
@observer
export default class OrganizationForm extends Form {
  defaultValues = {
    fullname: '',
    username: '',
    link: '',
    color: '',
    language: 'pt',
    country: '',
    confMessage: true,
    confMedia: true,
    confCommitment: true,
    confSurvey: true,
    confCharge: true,
    confReport: true,
    confMessageStudent: true,
    confReplyStudent: true,
    confRepliesStudent: true,
    confMediaStudent: true,
    confMessageStaff: true,
    confAdminList: true,
    confRecurrence: false,
    ntfStartTime: null,
    ntfEndTime: null,
    ntfWeekDaysArray: [],
    type: 'SCHOOL',
    plan: 'PREMIUM',
    collectionRule: [],
    defaultFeatures: ['TAGS', 'REPORTS', 'LABELS', 'FORMS'],
    labels: [],
    timezone: 'America/Sao_Paulo',
    usage: 'CLIENT',
    consultantId: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      isOverdueDisabled: false,
    };
    this.studentRepliesFeature =
      this.props.store.unleash &&
      this.props.store.unleash.getStatus('student-replies');
  }

  rules = {
    username: 'required',
    fullname: ['required', (value) => value.trim().length > 0],
    type: 'required',
    link: 'url',
  };

  openLabelForm = (type = 'add', label) => {
    if (type === 'edit') {
      this.props.store.appends.push(<LabelEdit label={label} />);
    } else {
      this.props.store.appends.push(
        <LabelAdd organization_id={this.props.organization.id} />,
      );
    }
  };

  onInputChangeSpecialCharacters = (e, { name, value, checked }) => {
    value = this.filterSpecialCharacters(value);
    this.onInputChange(e, { name, value, checked });
  };

  filterSpecialCharacters = (str) => str.replace(/[^\w\d]/g, '');

  beforeSubmit = (values) => {
    if (values.link && values.link.search('://') === -1) {
      values.link = 'http://' + values.link;
    }

    return values;
  };

  getTime = (where, name) => {
    const time =
      name === 'end'
        ? this.state.values.ntfEndTime
        : this.state.values.ntfStartTime;
    return time ? time.split(':')[where === 'hour' ? 0 : 1] : '';
  };

  setTime = (where = 'hour', value, name, cb) => {
    const time =
      (name === 'end'
        ? this.state.values.ntfEndTime
        : this.state.values.ntfStartTime) || '00:00';
    const split = time.split(':');
    const hour = split[0];
    const minute = split[1];

    if (where === 'hour') {
      this.setValue(
        name === 'end' ? 'ntfEndTime' : 'ntfStartTime',
        `${value}:${minute}`,
        cb,
      );
    } else if (where === 'minute') {
      this.setValue(
        name === 'end' ? 'ntfEndTime' : 'ntfStartTime',
        `${hour}:${value}`,
        cb,
      );
    }
  };

  toggleDay = (day) => {
    const { ntfWeekDaysArray } = this.state.values;
    const nwda = _.cloneDeep(ntfWeekDaysArray);
    const selectedDay = nwda.includes(day);
    if (selectedDay) {
      nwda.splice(nwda.indexOf(day), 1);
    } else {
      nwda.push(day);
    }
    this.setState({
      values: {
        ...this.state.values,
        ntfWeekDaysArray: nwda,
      },
    });
  };

  // eslint-disable-next-line no-undef
  handleOverdueBannerModal = (isOverdue) => {
    if (!isOverdue) {
      this.props.store.appends.push(
        <BetaModal
          id="InfoModal"
          onClose={() => {
            this.props.store.appends.pop();
          }}
          actions={[
            <BetaButton
              data-action="cancel"
              transparent
              round
              text={__('Cancel')}
              style={{ marginLeft: 0, marginRight: 'auto' }}
              onClick={() => {
                this.props.store.appends.pop();
              }}
            />,
            <BetaButton
              data-action="submit"
              round
              text={__('Yes, I want to enable')}
              style={{ marginRight: 0 }}
              onClick={() => {
                this.handleOverdueBanner(isOverdue);
                this.props.store.appends.pop();
              }}
              primary
            />,
          ]}
          header={__('Do you want to enable this banner?')}
          content={
            <div>
              <span style={{ fontSize: '1.143rem', fontWeight: 400 }}>
                {__(
                  '“Default Banner” will be displayed to organization administrators',
                )}
              </span>
            </div>
          }
        />,
      );
    } else {
      this.handleOverdueBanner(isOverdue);
    }
  };

  // eslint-disable-next-line no-undef
  handleOverdueBanner = (isOverdue) => {
    try {
      const { client, organization } = this.props;
      this.setState({ isOverdueDisabled: true });

      if (isOverdue) {
        const findFinancialBanner =
          organization.organizationBanners &&
          organization.organizationBanners.nodes &&
          organization.organizationBanners.nodes.find(
            (__banner) => __banner.bannerType === 'FINANCIAL_SUSPENDED',
          );

        if (findFinancialBanner) {
          client.mutate({
            mutation: DELETE_ORGANIZATION_BANNER,
            variables: {
              DeleteOrganizationBannerInput: {
                id: parseInt(findFinancialBanner.id, 10),
              },
            },
          });

          window.location.replace(`/organizations/${organization.id}/edit`);
        }
      } else {
        client.mutate({
          mutation: CREATE_ORGANIZATION_BANNER,
          variables: {
            CreateOrganizationBannerInput: {
              organizationId: organization && parseInt(organization.id, 10),
              bannerType: 'FINANCIAL_SUSPENDED',
            },
          },
        });

        window.location.replace(`/organizations/${organization.id}/edit`);
      }
    } catch (e) {
      console.error(e);
    }
  };

  handleArrivedModal(isEnabled) {
    this.props.store.appends.push(
      <BetaModal
        id="InfoModal"
        onClose={() => {
          this.props.store.appends.pop();
        }}
        actions={[
          <BetaButton
            data-action="cancel"
            transparent
            round
            text={__('Cancel')}
            style={{ marginLeft: 0, marginRight: 'auto' }}
            onClick={() => {
              this.props.store.appends.pop();
            }}
          />,
          <BetaButton
            data-action="submit"
            round
            text={
              isEnabled
                ? __('Yes, I want to disable')
                : __('Yes, I want to enable')
            }
            style={{ marginRight: 0 }}
            onClick={() => {
              this.handleArrived(isEnabled);
              this.props.store.appends.pop();
            }}
            primary
          />,
        ]}
        header={
          isEnabled
            ? __('Do you want to disable "Arrived?"')
            : __('Do you want to enable "Arrived?"')
        }
        content={
          <div>
            <span style={{ fontSize: '1.143rem', fontWeight: 400 }}>
              {isEnabled
                ? __(
                  'The feature "Arrived" will be disabled for this organization',
                )
                : __(
                  'The feature "Arrived" will be available for this organization',
                )}
            </span>
          </div>
        }
      />,
    );
  }

  handleArrived(isEnabled) {
    try {
      const {
        organization,
        enableProductFeatureMutation,
        disableProductFeatureMutation,
      } = this.props;
      const mutationVariables = {
        id: organization.id,
        identifier: 'arrived',
      };

      if (isEnabled) {
        disableProductFeatureMutation({
          variables: mutationVariables,
        });
      } else {
        enableProductFeatureMutation({
          variables: mutationVariables,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  showNewSectionStyle = (divider, section, button, divProps = {}) => {
    if (this.studentRepliesFeature) {
      return (
        <div {...divProps}>
          <Divider section />
          {divider}
          {button}
          {section}
        </div>
      );
    }
    return (
      <div>
        {divider}
        {button}
        {section}
      </div>
    );
  };

  /**
   * @returns {JSX.Element}
   */
  showNewColorPlanStyle = (colorChild, planChild) => {
    if (this.studentRepliesFeature && planChild) {
      return (
        <Form.Field className="field-group">
          {colorChild}
          {planChild}
        </Form.Field>
      );
    }
    return [colorChild, planChild];
  };

  render() {
    const { values, errors, isOverdueDisabled } = this.state;
    const { organization, user, store, add } = this.props;
    const labels = organization?.labels || [];

    const organizationBanners =
      (organization &&
        organization.organizationBanners &&
        organization.organizationBanners.nodes) ||
      [];
    const hasOrganizationBanners =
      organizationBanners &&
      organizationBanners.find(
        (__banner) => __banner.bannerType === 'FINANCIAL_SUSPENDED',
      );

    const isOverdue = !!hasOrganizationBanners;

    const isArrivedEnabled =
      store.currentOrganization &&
      store.currentOrganization.productFeatures &&
      store.currentOrganization.productFeatures.nodes &&
      store.currentOrganization.productFeatures.nodes.some(
        (feature) => feature.identifier === 'arrived',
      );

    const canUpdateOrganization =
      store.currentOrganization &&
      store.currentOrganization.permissions &&
      store.currentOrganization.permissions.entityScopes &&
      store.currentOrganization.permissions.entityScopes.includes(
        'UPDATE_ORGANIZATION',
      );

    const canCreateLabels =
      (store.currentEntity &&
        store.currentEntity.type &&
        store.currentEntity.type === 'ADMIN') ||
      canUpdateOrganization;

    const isMaster = store.currentUser && store.currentUser.isMaster;

    const isShowMasterSettings = user && user.isMaster && !add;

    return (
      <Form id="OrganizationForm" {...this.props} onSubmit={this.handleSubmit}>
        {isShowMasterSettings && (
          <div>
            <Divider section horizontal>
              {__('Master Settings')}
            </Divider>
            <Segment className={this.studentRepliesFeature ? 'new' : ''}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Switch
                  checked={isOverdue}
                  onChange={() => this.handleOverdueBannerModal(isOverdue)}
                  onColor="#084FFF"
                  onHandleColor="#FFFFFF"
                  offColor="#E7E7E7"
                  handleDiameter={29}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  disabled={isOverdueDisabled}
                  height={36}
                  width={67}
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: '9px',
                  }}
                >
                  <span>{__('Overdue banner')}</span>
                  <span style={{ color: '#666666' }}>
                    {isOverdue ? __('Enabled') : __('Disabled')}
                  </span>
                </div>
              </div>
              {!isNetwork(organization) && (
                <ArrivedSwitch
                  checked={isArrivedEnabled}
                  onChange={() => this.handleArrivedModal(isArrivedEnabled)}
                  hasStudentRepliesFeature={!!this.studentRepliesFeature}
                />
              )}
            </Segment>
          </div>
        )}
        <Divider section horizontal>
          {__('Basic information')}
        </Divider>
        <Segment
          className={this.studentRepliesFeature ? 'new org-info' : ''}
          style={{ zIndex: 1 }}
        >
          <Form.Group widths="equal">
            <Form.Input
              label={__('Name')}
              placeholder={__('Name')}
              name="fullname"
              value={values.fullname || ''}
              onChange={this.onInputChange}
              error={errors && errors.fullname}
            />
            {errors && errors.fullname ? (
              <div style={{ marginTop: 24 }}>
                <Popup
                  trigger={
                    <Button
                      type="button"
                      icon="exclamation triangle"
                      basic
                      color="red"
                    />
                  }
                  content={__('Organization name cannot be empty')}
                />
              </div>
            ) : null}

            <Form.Field>
              <label htmlFor="username">{__('Username')}</label>
              <Input
                label="classapp.co/"
                placeholder={__('Username')}
                name="username"
                value={values.username || ''}
                onChange={this.onInputChangeSpecialCharacters}
                error={errors && errors.username}
              />
            </Form.Field>
            {errors && errors.username ? (
              <div style={{ marginTop: 24 }}>
                <Popup
                  trigger={
                    <Button
                      type="button"
                      icon="exclamation triangle"
                      basic
                      color="red"
                    />
                  }
                  content={__('Username cannot be empty')}
                />
              </div>
            ) : null}
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Input
              label={__('Link')}
              placeholder={__('Link')}
              name="link"
              value={values.link || ''}
              onChange={this.onInputChange}
            />
            {errors && errors.link ? (
              <div style={{ marginTop: 24 }}>
                <Popup
                  trigger={
                    <Button
                      type="button"
                      icon="exclamation triangle"
                      basic
                      color="red"
                    />
                  }
                  content={__('Link cannot be empty')}
                />
              </div>
            ) : null}

            {this.showNewColorPlanStyle(
              <Form.Field>
                <label htmlFor="color">{__('Theme color')}</label>
                <Popup
                  on="click"
                  position="top right"
                  trigger={
                    <Button
                      secondary
                      as="span"
                      icon="paint brush"
                      data-name="theme-color"
                      style={{ backgroundColor: '#' + values.color }}
                      onClick={() =>
                        this.setState({ pickColor: !this.state.pickColor })
                      }
                    />
                  }
                  content={
                    <TwitterPicker
                      triangle="hide"
                      color={values.color || undefined}
                      onChange={(color) =>
                        this.setState({
                          values: { ...values, color: color.hex.slice(1) },
                        })
                      }
                    />
                  }
                />
              </Form.Field>,
              user && user.isMaster && (
                <Form.Dropdown
                  label={__('Plan')}
                  name="plan"
                  selection
                  data-value={values.plan}
                  value={values.plan}
                  placeholder={__('Select type')}
                  options={planOptions}
                  onChange={this.onSelectionChange}
                />
              ),
            )}
          </Form.Group>
          <Form.Group widths="equal">
            <Popup
              trigger={
                <Form.Dropdown
                  label={__('Language')}
                  name="language"
                  selection
                  data-value={values.language}
                  value={values.language}
                  placeholder={__('Select language')}
                  options={getLanguageOptions()}
                  onChange={this.onSelectionChange}
                />
              }
              content={
                <span>
                  {__(
                    'This field does not affect the system language, it only affects the invitations language',
                  )}
                </span>
              }
              hideOnScroll
            />

            <Form.Dropdown
              label={__('Country')}
              name="country"
              selection
              data-value={values.country}
              value={values.country}
              placeholder={__('Select country')}
              options={getCountryOptions()}
              onChange={this.onSelectionChange}
            />
            <Form.Dropdown
              label={__('Type')}
              name="type"
              selection
              data-value={values.type}
              value={values.type}
              placeholder={__('Select type')}
              options={getTypeOptions()}
              onChange={this.onSelectionChange}
            />
          </Form.Group>

          {user && user.isMaster && (
            <Form.Group widths="equal">
              <Form.Dropdown
                label={__('Timezone')}
                name="timezone"
                selection
                search
                data-value={values.timezone}
                value={values.timezone}
                placeholder={__('Select timezone')}
                options={zones().map((zone) => ({ text: zone, value: zone }))}
                onChange={this.onSelectionChange}
              />
              <Form.Dropdown
                label={__('Client type')}
                name="usage"
                selection
                data-value={values.usage}
                value={values.usage}
                placeholder={__('Select client type')}
                options={usageOptions}
                onChange={this.onSelectionChange}
              />
              <Form.Dropdown
                label={__('Consultant')}
                name="consultantId"
                selection
                search
                data-value={values.consultantId}
                value={values.consultantId}
                placeholder={__('Select consultant')}
                options={this.props.channels}
                onChange={this.onSelectionChange}
              />
            </Form.Group>
          )}
        </Segment>

        {organization && (
          <TagsList
            browserHistory={browserHistory}
            organization={organization}
            hasMaster={user?.isMaster}
            isAdmin={store.currentEntity.type === 'ADMIN'}
            tags={organization.tags}
            hasStudentRepliesFeature={!!this.studentRepliesFeature}
            isNetworkChild={isNetworkChild(organization)}
          />
        )}

        {organization &&
          !isNetwork(organization) &&
          organization.features &&
          organization.features.labels &&
          ((organization.labels && organization.labels.length) ||
            store.currentEntity.type === 'ADMIN') && (
            <LabelsSection
              labels={labels}
              studentRepliesFeature={this.studentRepliesFeature}
              canCreateLabel={canCreateLabels}
              canUpdateOrganization={canUpdateOrganization}
              openLabelForm={this.openLabelForm}
              isMaster={isMaster}
            />
        )}
        {getFunctionalityOptions({
          organization: organization || { type: values.type },
          settings: values,
          props: this.props,
          studentRepliesFeature: this.studentRepliesFeature,
          isNetwork: isNetwork(organization),
        }).map(
          (functionality) =>
            functionality.condition &&
            this.showNewSectionStyle(
              <Divider section horizontal>
                {functionality.text}
              </Divider>,
              <Segment
                className={
                  (this.studentRepliesFeature ? 'new ' : '') +
                  (functionality.className || '')
                }
              >
                {functionality.values
                  .filter((field) => !!field)
                  .map((field) => {
                    const subcomponents =
                      (functionality.customComponent &&
                        functionality.customComponent.condition &&
                        functionality.customComponent.components) ||
                      field.components;
                    if (
                      field.enabled &&
                      (!field.type || field.type === 'CHECKBOX')
                    ) {
                      return (
                        <div>
                          <Form.Checkbox
                            toggle
                            data-value={!!values[field.value]}
                            key={field.text}
                            label={field.text}
                            name={field.value}
                            checked={!!values[field.value] || field.checked}
                            className={field.className || ''}
                            onClick={() => {
                              if (field.onClick) {
                                field.onClick(this);
                              } else {
                                this.onInputChange(null, {
                                  name: field.value,
                                  checked: !values[field.value],
                                });
                              }
                            }}
                          />
                          {subcomponents &&
                            subcomponents.map((component) => {
                              if (component.type === 'GROUP') {
                                return (
                                  <Form.Group style={component.styles}>
                                    {component.subcomponents.map(
                                      (subcomponent) => {
                                        if (subcomponent.type === 'DROPDOWN') {
                                          return (
                                            <Form.Dropdown
                                              name={subcomponent.name}
                                              compact
                                              selection
                                              placeholder={
                                                subcomponent.placeholder
                                              }
                                              value={subcomponent.value(this)}
                                              disabled={subcomponent.disabled}
                                              options={subcomponent.options}
                                              onChange={(e, { value }) =>
                                                subcomponent.onChange(this, {
                                                  value,
                                                })
                                              }
                                              {...subcomponent.props}
                                            />
                                          );
                                        } else if (
                                          subcomponent.type === 'TEXT'
                                        ) {
                                          return subcomponent.text;
                                        } else if (
                                          subcomponent.type === 'CHECKBOX'
                                        ) {
                                          return (
                                            <Form.Checkbox
                                              toggle
                                              data-value={
                                                subcomponent.inverted
                                                  ? !values[subcomponent.value]
                                                  : !!values[subcomponent.value]
                                              }
                                              key={subcomponent.text}
                                              label={subcomponent.text}
                                              name={subcomponent.value}
                                              disableWithoutBanIcon={
                                                subcomponent.disableWithoutBanIcon
                                              }
                                              disabled={subcomponent.disabled}
                                              checked={
                                                subcomponent.inverted
                                                  ? !values[subcomponent.value]
                                                  : !!values[
                                                    subcomponent.value
                                                  ] || subcomponent.checked
                                              }
                                              onClick={() => {
                                                if (subcomponent.onClick) {
                                                  subcomponent.onClick(this);
                                                } else {
                                                  this.onInputChange(null, {
                                                    name: subcomponent.value,
                                                    checked:
                                                      !values[
                                                        subcomponent.value
                                                      ],
                                                  });
                                                }
                                              }}
                                            />
                                          );
                                        }
                                      },
                                    )}
                                  </Form.Group>
                                );
                              } else if (component.type === 'DIV') {
                                return (
                                  <div id="daysOfWeek" style={component.styles}>
                                    {component.subcomponents.map(
                                      (subcomponent) => {
                                        if (subcomponent.type === 'BUTTON') {
                                          return (
                                            <Button
                                              circular
                                              id={subcomponent.text}
                                              disabled={subcomponent.disabled}
                                              key={subcomponent.text}
                                              style={subcomponent.styles}
                                              onClick={() =>
                                                subcomponent.onClick(this)
                                              }
                                            >
                                              <a
                                                style={subcomponent.linkStyles}
                                              >
                                                {subcomponent.text.charAt(0)}
                                              </a>
                                            </Button>
                                          );
                                        }
                                      },
                                    )}
                                  </div>
                                );
                              }
                            })}
                        </div>
                      );
                    } else if (field.type === 'LABEL') {
                      return (
                        field.condition && (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              ...field.styles,
                            }}
                          >
                            {field.icon && <Icon {...field.icon} />}
                            <div>{field.text}</div>
                          </div>
                        )
                      );
                    }
                    console.log('field', field);
                    return (
                      <Popup
                        trigger={
                          <div>
                            <Form.Checkbox
                              toggle
                              key={field.text}
                              label={field.text}
                              name={field.value}
                              checked={!!values[field.value]}
                              onClick={() =>
                                field.onClick
                                  ? field.onClick(this, field)
                                  : this.onInputChange(null, {
                                    name: field.value,
                                    checked: !values[field.value],
                                  })
                              }
                              disabled
                              style={{ opacity: '.3' }}
                            />
                          </div>
                        }
                        content={
                          <span>
                            {!values.confMedia && values.confMessage
                              ? __(
                                'You cannot change this option if the send media option is disabled.',
                              )
                              : __(
                                "You can't change this option if messages are disabled",
                              )}
                          </span>
                        }
                      />
                    );
                  })}
              </Segment>,
              null,
              { key: functionality.text },
            ),
        )}
        <Divider />
        {this.props.children}
      </Form>
    );
  }
}
