/* eslint-disable prettier/prettier */
import React from 'react';
import moment from 'moment';

import { __ } from '../../../../i18n';
import * as utils from '../../../../utils';
import { getTimeOptions } from './getTimeOptions';
import { getWeekDays } from './getWeekDays';

const styles = {
  dayButton: {
    display: 'flex',
    width: 48,
    height: 48,
    borderRadius: 24,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 4,
    marginBottom: 0,
  },
};

/**
 * @param {{
 *  organization: any,
 *  settings: any,
 *  props: any,
 *  studentRepliesFeature: boolean,
 *  isNetwork: boolean,
 * }} props
 */
export function getFunctionalityOptions({
  organization,
  settings,
  props,
  studentRepliesFeature,
  isNetwork,
}) {
  return [
    {
      condition: true,
      text: __('General functionality'),
      values: [
        {
          text: __('Messages can be sent'),
          value: 'confMessage',
          enabled: true,
        },
        {
          text: __('Media can be sent'),
          value: 'confMedia',
          enabled: settings?.confMessage,
        },
        !isNetwork && {
          text: __('Commitments can be sent'),
          value: 'confCommitment',
          enabled: settings?.confMessage,
        },
        !isNetwork && {
          text: __('Surveys can be sent'),
          value: 'confSurvey',
          enabled: settings?.confMessage,
        },
        // { text: __('Charges can be sent'), value: 'confCharge', enabled: settings?.confMessage },
        !isNetwork && {
          text: __('Reports can be sent'),
          value: 'confReport',
          enabled: settings?.confMessage,
        },
      ],
    },
    {
      condition: !isNetwork,
      className: 'row',
      text:
        utils.checkIfItIsClientOrSchool(organization.type) === 1
          ? __('Student/Parent functionality')
          : __('Client functionality'),
      values: [
        {
          text:
            utils.checkIfItIsClientOrSchool(organization.type) === 1
              ? __('Parent/student can send messages')
              : __('Clients can send message'),
          value: 'confMessageStudent',
          enabled: settings?.confMessage,
          className: 'teste',
        },
        {
          text:
            utils.checkIfItIsClientOrSchool(organization.type) === 1
              ? __('Parent/student can reply')
              : __('Clients can reply'),
          value: 'confReplyStudent',
          className: 'teste',
          enabled: true,
          onClick: (e) => {
            e.onInputChange(null, {
              name: 'confReplyStudent',
              checked: !e.state.values.confReplyStudent,
            });
            if (studentRepliesFeature && !e.state.values.confReplyStudent) {
              e.onInputChange(null, {
                name: 'confRepliesStudent',
                checked: true,
              });
            }
          },
          components: studentRepliesFeature
            ? [
              {
                type: 'GROUP',
                subcomponents: [
                  {
                    type: 'CHECKBOX',
                    text:
                        utils.checkIfItIsClientOrSchool(organization.type) === 1
                          ? __(
                            'Do not allow successive replies from guardians and students',
                          )
                          : __('Do not allow successive replies for clients'),
                    value: 'confRepliesStudent',
                    disabled: !settings?.confReplyStudent,
                    disableWithoutBanIcon: !settings?.confReplyStudent,
                    inverted: true,
                  },
                ],
              },
            ]
            : null,
        },
        {
          text:
            utils.checkIfItIsClientOrSchool(organization.type) === 1
              ? __('Parent/student can send media')
              : __('Clients can send media'),
          value: 'confMediaStudent',
          enabled: settings?.confMessage && settings?.confMedia,
          className: 'teste',
        },
        {
          condition: studentRepliesFeature && !settings?.confRepliesStudent,
          type: 'LABEL',
          styles: {
            backgroundColor: '#FAFAFA',
            borderRadius: '10px',
            padding: '10px',
            alignItems: 'center',
          },
          icon: {
            name: 'info circle',
            style: {
              color: 'gray',
              fontSize: '22px',
              fontWeight: 'bold',
              marginRight: '10px',
            },
          },
          text: utils.parseFormattedTranslation({
            text:
              utils.checkIfItIsClientOrSchool(organization.type) === 1
                ? __(
                  'By checking the "**Do not allow successive replies from guardians and students**" option, your organization retains the old ClassApp experience, in which parents and students can only reply after they have been replied to by a staff member or administrator.',
                )
                : __(
                  'By checking the "**Do not allow successive replies from clients**" option, your organization retains the old ClassApp experience, in which clients can only reply after they have been replied to by a staff member or administrator.',
                ),
            Wrapper: ({ children }) => <b>{children}</b>,
          }),
        },
      ],
    },
    {
      condition: true,
      className: 'row',
      text: __('Staff functionality'),
      values: [
        {
          text: __('Staff can send messages'),
          value: 'confMessageStaff',
          enabled: settings?.confMessage,
        },
        {
          text: __('Show staff list'),
          value: 'confAdminList',
          enabled: settings?.confMessage,
        },
      ],
    },
    {
      condition: organization.features && organization.features.doNotDisturb,
      className: 'row',
      text: __('Notification settings'),
      values: [
        {
          text: __('Customize when notifying users in the organization'),
          value: 'customNotification',
          enabled: true,
          checked: settings?.ntfStartTime && settings?.ntfEndTime,
          onClick: (e) => {
            if (settings?.ntfStartTime && settings?.ntfEndTime) {
              e.onInputChange(null, { name: 'ntfStartTime', value: null });
              e.onInputChange(null, { name: 'ntfEndTime', value: null });
              e.onInputChange(null, { name: 'ntfWeekDaysArray', value: [] });
            } else {
              e.onInputChange(null, {
                name: 'ntfStartTime',
                value: '08:00:00',
              });
              e.onInputChange(null, { name: 'ntfEndTime', value: '18:00:00' });
              e.onInputChange(null, {
                name: 'ntfWeekDaysArray',
                value: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
              });
            }
          },
        },
      ],
      customComponent: {
        condition: settings?.ntfStartTime && settings?.ntfEndTime,
        components: [
          {
            type: 'GROUP',
            styles: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '0px',
              paddingTop: '16px',
            },
            subcomponents: [
              {
                type: 'DROPDOWN',
                name: 'startHour',
                placeholder: '--',
                value: (e) => e.getTime('hour', 'start'),
                disabled:
                  (!settings?.ntfStartTime && !settings?.ntfEndTime) ||
                  (settings?.ntfStartTime === '00:00:00' &&
                    settings?.ntfEndTime === '23:59:59'),
                options: getTimeOptions().hour,
                onChange: (e, { value }) => {
                  e.setTime('hour', value, 'start', () => {
                    if (
                      moment(settings?.ntfEndTime, 'hh:mm:ss').isBefore(
                        moment(settings?.ntfStartTime, 'hh:mm:ss'),
                      )
                    ) {
                      alert(
                        __(
                          'You can not set an opening time to start after the end time.',
                        ),
                      );
                      e.setValue('ntfEndTime', settings?.ntfStartTime);
                    }
                  });
                },
                props: {
                  upward: true,
                },
              },
              {
                type: 'DROPDOWN',
                name: 'startMinute',
                placeholder: '--',
                value: (e) => e.getTime('minute', 'start'),
                disabled:
                  (!settings?.ntfStartTime && !settings?.ntfEndTime) ||
                  (settings?.ntfStartTime === '00:00:00' &&
                    settings?.ntfEndTime === '23:59:59'),
                options: getTimeOptions(settings?.ntfEndTime === '23:59:59').minute,
                onChange: (e, { value }) => {
                  e.setTime('minute', value, 'start', () => {
                    if (
                      moment(settings?.ntfEndTime, 'hh:mm:ss').isBefore(
                        moment(settings?.ntfStartTime, 'hh:mm:ss'),
                      )
                    ) {
                      alert(
                        __(
                          'You can not set an opening time to start after the end time.',
                        ),
                      );
                      e.setValue('ntfEndTime', settings?.ntfStartTime);
                    }
                  });
                },
                props: {
                  upward: true,
                },
              },
              {
                type: 'TEXT',
                text: __('until'),
              },
              {
                type: 'DROPDOWN',
                name: 'endHour',
                placeholder: '--',
                value: (e) => e.getTime('hour', 'end'),
                disabled:
                  (!settings?.ntfStartTime && !settings?.ntfEndTime) ||
                  (settings?.ntfStartTime === '00:00:00' &&
                    settings?.ntfEndTime === '23:59:59'),
                options: getTimeOptions().hour,
                onChange: (e, { value }) => {
                  e.setTime('hour', value, 'end', () => {
                    if (
                      moment(settings?.ntfStartTime, 'hh:mm:ss').isAfter(
                        moment(settings?.ntfEndTime, 'hh:mm:ss'),
                      )
                    ) {
                      alert(
                        __(
                          'You can not set an end time to end before the start time.',
                        ),
                      );
                      e.setValue('ntfStartTime', settings?.ntfEndTime);
                    }
                  });
                },
                props: {
                  upward: true,
                },
              },
              {
                type: 'DROPDOWN',
                name: 'endMinute',
                placeholder: '--',
                value: (e) => e.getTime('minute', 'end'),
                disabled:
                  (!settings?.ntfStartTime && !settings?.ntfEndTime) ||
                  (settings?.ntfStartTime === '00:00:00' &&
                    settings?.ntfEndTime === '23:59:59'),
                options: getTimeOptions(settings?.ntfEndTime === '23:59:59').minute,
                onChange: (e, { value }) => {
                  e.setTime('minute', value, 'end', () => {
                    if (
                      moment(settings?.ntfStartTime, 'hh:mm:ss').isAfter(
                        moment(settings?.ntfEndTime, 'hh:mm:ss'),
                      )
                    ) {
                      alert(
                        __(
                          'You can not set an end time to end before the start time.',
                        ),
                      );
                      e.setValue('ntfStartTime', settings?.ntfEndTime);
                    }
                  });
                },
                props: {
                  upward: true,
                },
              },
              {
                type: 'CHECKBOX',
                text: __('All day'),
                value: 'allDay',
                enabled: true,
                checked:
                  settings?.ntfStartTime === '00:00:00' &&
                  settings?.ntfEndTime === '23:59:59',
                onClick: (e) => {
                  if (
                    settings?.ntfStartTime === '00:00:00' &&
                    settings?.ntfEndTime === '23:59:59'
                  ) {
                    e.onInputChange(null, {
                      name: 'ntfStartTime',
                      value: '08:00:00',
                    });
                    e.onInputChange(null, {
                      name: 'ntfEndTime',
                      value: '18:00:00',
                    });
                  } else {
                    e.onInputChange(null, {
                      name: 'ntfStartTime',
                      value: '00:00:00',
                    });
                    e.onInputChange(null, {
                      name: 'ntfEndTime',
                      value: '23:59:59',
                    });
                  }
                },
              },
            ],
          },
          {
            type: 'DIV',
            styles: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
              marginTop: 16,
              marginBottom: 0,
            },
            subcomponents: getWeekDays().map((day) => ({
              type: 'BUTTON',
              disabled: !settings?.ntfStartTime && !settings?.ntfEndTime,
              text: day.text,
              styles: {
                ...styles.dayButton,
                backgroundColor: settings?.ntfWeekDaysArray?.includes(day.value)
                  ? '#0080FF'
                  : '#fff',
                border: settings?.ntfWeekDaysArray?.includes(day.value)
                  ? null
                  : 'solid 1px #e9ecef',
              },
              onClick: (e) => e.toggleDay(day.value),
              linkStyles: {
                fontSize: 13,
                color: settings?.ntfWeekDaysArray?.includes(day.value)
                  ? '#fff'
                  : '#212529',
              },
            })),
          },
        ],
      },
    },
    {
      condition: props.user && props.user.isMaster && !isNetwork,
      className: 'row',
      text: __('Recurrence settings'),
      values: [
        {
          text: __('Recurrence module available'),
          value: 'recurrenceAvailable',
          enabled: true,
          checked: settings?.confRecurrence,
          onClick: (e) => {
            if (settings?.confRecurrence) {
              e.onInputChange(null, { name: 'confRecurrence', value: false });
            } else {
              e.onInputChange(null, { name: 'confRecurrence', value: true });
            }
          },
        },
      ],
    },
    {
      condition: props.user && props.user.isMaster && props.add,
      text: __('Create organization settings (master only)'),
      values: [
        {
          text: __('Create default features'),
          value: 'defaultFeatures',
          enabled: true,
          onClick: (e) => {
            if (!e.state.values.defaultFeatures) {
              e.onInputChange(null, {
                name: 'defaultFeatures',
                value: ['TAGS', 'REPORTS', 'LABELS', 'FORMS'],
              });
            } else {
              e.onInputChange(null, { name: 'defaultFeatures', value: null });
            }
          },
        },
      ],
    },
  ];
}
