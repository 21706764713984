/*eslint prefer-const: off*/
/*eslint-env es6*/
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import { Icon, Button, Header, Popup, Loader } from 'semantic-ui-react';
import Paginator from '../../components/Paginator';
import Table from '../../components/Table';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { cloneDeep, every } from 'lodash';

import Page from '../../components/Page';
import Modal from '../../components/Modal';
import BetaModal from '../../components/ui/Modal';
import BetaButton from '../../components/ui/Button';
import Avatar from '../../components/Avatar';

import RoleForm from '../Role/Form';
import EntityPatch from '../Entity/Patch';

import { __ } from '../../i18n';
import * as utils from '../../utils';
import ChatNews from '../../app/Presentation/ChatNews/ViewUI'
import { shouldShowChatNewsModal } from '../../app/Presentation/ChatNews/ViewModel';
import { hasConversationProductFeature } from '../../helpers/hasConversationProductFeature';

import * as analytics from '../../lib/analytics';

const PAGE_SIZE = 40;

const updateEntity = input => `updateEntity_${input.id}: updateEntity(input: ${JSON.stringify(input).replace(/\"([^(\")"]+)\":/g, '$1:')}) {
    clientMutationId
}`;

const inviteEntity = input => `updateEntity_${input.id}: updateEntity(input: { id: ${input.id}, invite: ${input.invite} }) {
    clientMutationId
}`;

const deleteChannel = input => `deleteChannel_${input.id}: deleteEntity(input: { id: ${input.id} }) {
    clientMutationId
}`;

@inject('store', 'api', 'client')
@graphql(gql`query OrganizationChannelQuery (
  $id: ID!,
  $limit: Int,
  $offset: Int,
  $search: String,
  $entityType: [EntityType]
  $groups: [ID]
  $status: OrganizationEntitiesStatus
  $orderBy: [OrganizationEntitiesOrder]
  $noRole: Boolean
  $seeAll: Boolean
  $getAllEntities: Boolean
  $isChannel: Boolean
) {
    node(id: $id) @connection(key: "Organization", filter: ["id"]) {
      ... on Organization {
        id: dbId
        plan
        premiumPeriod
        isDemonstration
        monthlyMessagesCount
        monthlyMessageQuota
        entities (limit: $limit, offset: $offset, search: $search, type: $entityType, groups: $groups, status: $status, orderBy: $orderBy, noRole:$noRole, seeAll:$seeAll, getAllEntities: $getAllEntities, isChannel: $isChannel) {
          totalCount
          nodes {
            id: dbId
            fullname
            eid
            picture {
              uri
              id: dbId
              key
            }
            type
            code
            description
            invisible
            disabled
            seeAll
            confAttendanceEnabled
            selected
            users {
              totalCount
            }
            invitedAddresses: addresses (invited: true) {
              totalCount
            }
            addresses {
              totalCount
            }
            roles (limit: 400) {
              nodes {
                id: dbId
                name
              }
            }
            groups (limit: 400) {
              nodes {
                id: dbId
                name
              }
              totalCount
            }
          }
        }
        groups (limit: 400) {
          nodes {
            id: dbId
            name
          }
          totalCount
        }
      }
    }
  }
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: ownProps.params.organization_id,
      limit: parseInt(ownProps.location.query.limit || PAGE_SIZE, 10),
      offset: parseInt(((ownProps.location.query.p || 1) - 1) * (ownProps.location.query.limit || PAGE_SIZE), 10),
      search: ownProps.location.query.search || '',
      getAllEntities: !!ownProps.location.query.search,
      entityType: (ownProps.location.query.entityType && [ownProps.location.query.entityType]) || [],
      groups: (ownProps.location.query.groupsIds && JSON.parse(ownProps.location.query.groupsIds)) || [],
      status: ownProps.location.query.status || null,
      orderBy: (ownProps.location.query.orderBy && JSON.parse(ownProps.location.query.orderBy)) || (ownProps.location.query.search ? { column: 'DISABLED', direction: 'ASC' } : { column: 'CREATED', direction: 'DESC' }),
      noRole: ownProps.location.query.noGroup && JSON.parse(ownProps.location.query.noGroup),
      seeAll: ownProps.location.query.seeAll && JSON.parse(ownProps.location.query.seeAll),
      isChannel: true
    }
  })
})
@observer
export default class OrganizationEntities extends Page {
  constructor(props) {
    super(props);

    const hasMenuConversations = this.props.store?.currentOrganization?.unleashStatus?.web_menu_conversations && hasConversationProductFeature(this.props.store.currentOrganization);
    const desktopColumns = hasMenuConversations
      ? [__('Name'), __('Description'), __('Channel Type'), __('Groups'), __('Users'), __('Status'), '']
      : [__('Name'), __('Description'), __('Groups'), __('Users'), __('Status'), ''];

    this.colNames = this.isMobile() ? [__('Name'), ''] : desktopColumns;
    const { location } = props;
    const query = { ...location.query };
    const status = [
      { text: __('Registered'), value: 'WITH_USERS' },
      { text: __('Not registered'), value: 'NO_USERS' },
      { text: __('Invited but not registered'), value: 'WITH_INVITES_NO_USERS' },
      { text: __('No addresses'), value: 'NO_ADDRESSES' },
      { text: __('Disabled'), value: 'DISABLED' }
    ];
    const order = [
      { text: __('Created'), value: '{"column":"CREATED","direction":"DESC"}' },
      { text: __('Name A-Z'), value: '{"column":"FULLNAME","direction":"ASC"}' },
      { text: __('Name Z-A'), value: '{"column":"FULLNAME","direction":"DESC"}' }
    ];

    this.state = {
      groups: [],
      status: status.map(e => ({ value: e.value, text: e.text, selected: query.status === e.value })),
      order: order.map(e => ({ value: e.value, text: e.text, selected: query.orderBy === e.value })),
      selectedGroups: [],
      selectAll: false,
      open: false,
      screenWidth: window.innerWidth,
      shouldShowConversationModal: false,
    };

    this.updateWindowWidth = this.updateWindowWidth.bind(this);
  }


  componentDidMount() {
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);
    try {
      if (hj && this.props.store.currentOrganization.plan === 'FREE') {
        hj('trigger', 'pessoasfree');
      }
    } catch (e) {
      console.error(e);
    }
    const shouldShow = shouldShowChatNewsModal(this.props.store);
    this.setState({ shouldShowConversationModal: shouldShow });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  updateWindowWidth() {
    this.setState({ screenWidth: window.innerWidth });
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, client, store } = nextProps;

    if ((data.loading && !data.node) || !data.node) return;

    const { totalCount, nodes } = data.node.groups;

    if (totalCount === nodes.length) return;

    try {
      const response = await client.query({
        query: gql`
          query OrganizationEntitiesGroupsQuery($id: ID!, $limit: Int) {
            node (id: $id) {
              ... on Organization {
                id: dbId
                groups (limit: $limit) {
                  nodes {
                    id: dbId
                    name
                  }
                }
              }
            }
          }`,
        variables: {
          id: store.currentOrganization.id,
          limit: totalCount
        }
      });


      data.updateQuery((previousResult) => {
        const nextResult = cloneDeep(previousResult);
        nextResult.node.groups.nodes = response.data.node.groups.nodes;

        return nextResult;
      });
    } catch (e) {
      console.error(e);
    }
  }

  onEntitySelect = (entity_id) => {
    const { data } = this.props;

    data.updateQuery((previousResult) => {
      const newEntities = previousResult.node.entities.nodes.map(node => (node.id !== entity_id ? node : { ...node, selected: !node.selected }));

      return Object.assign({}, previousResult, {
        node: { ...previousResult.node, entities: { ...previousResult.node.entities, nodes: newEntities } },
      });
    });

    this.setState({ selectAll: false });
  }

  onSelectAll = () => {
    const selectAll = !this.state.selectAll;
    const { data, store } = this.props;

    data.updateQuery((previousResult) => {
      const newEntities = previousResult.node.entities.nodes.map(node => ({ ...node, selected: store.currentEntity.type === 'ADMIN' || node.type !== 'ADMIN' ? selectAll : node.selected }));

      return Object.assign({}, previousResult, {
        node: { ...previousResult.node, entities: { ...previousResult.node.entities, nodes: newEntities } },
      });
    });

    this.setState({ selectAll });
  }

  onSubmitError = (params) => {
    if (params && params.values && params.values.name && params.values.name && (params.values.name === 'disable' || params.values.name === 'delete' || params.values.name === 'invite')) {
      this.props.store.snackbar = { active: true, message: __('Select at last one channel'), success: false };
    }
  }

  confirmModal = (selectedEntities = [], name = 'disable') => {
    let title = '';
    let message = '';
    let submitButton = '';
    let red = false;
    const values = { entities: selectedEntities.map(e => e.id), name };

    if (name === 'disable') {
      title = __('Disable channels');
      message = __('Are you sure you want to disable these channels?');
      submitButton = __('Disable');
    } else if (name === 'delete') {
      title = __('Delete channels');
      message = __('Are you sure you want to delete these channels?');
      submitButton = __('Delete');
      red = true;
    } else if (name === 'enable') {
      title = __('Enable channels');
      message = __('Are you sure you want to enable these channels?');
      submitButton = __('Enable');
    } else if (name === 'invite') {
      title = __('Invite channels');
      message = __('Are you sure you want to invite these channels?');
      submitButton = __('Invite');
      values.invite = 'EMAIL';
    } else if (name === 'transform') {
      title = __('Transform channels into people');
      message = __('Are you sure about that? When you do this, these channels will be located in the People menu.');
      submitButton = __('Transform');
    }

    this.props.store.appends.push(
      <BetaModal
        data-title="confirm-modal"
        data-params={name}
        id={'EntityPatch'}
        onClose={() => this.props.store.appends.pop()}
        header={title}
        content={
          <EntityPatch
            modal
            type={'channel'}
            message={message}
            values={values}
            entityTypes={name === 'transform' && ['ADMIN', 'STAFF']}
            entities={this.props.data.node.entities.nodes}
            onSubmit={this.updateEntities}
            onSubmitError={this.onSubmitError}
            submitButton={submitButton}
            red={red}
          />
        }
        actions={[
          <BetaButton
            data-action="cancel"
            key={0}
            floated="left"
            round
            transparent
            text={__('Cancel')}
            onClick={() => this.props.store.appends.pop()}
          />
        ]}
      />
    );
  }

  renderUpdateEntitiesError = errors => this.props.store.appends.push(
    <Modal
      onClose={() => this.props.store.appends.pop()}
      size="tiny"
      actions={[<Button data-action="submit" primary content={__('OK')} onClick={() => this.props.store.appends.pop()} />]}
      header={__('Errors occurred updating entities')}
      content={<div>
        {errors.map((err) => {
          const entity = this.props.data.node.entities.nodes.find(e => e.id == err.id);

          return (
            <Header as="h5" className="entityItem" title={entity.fullname} data-id={entity.id} style={{ display: 'flex', alignItems: 'flex-start' }}>
              <Avatar avatar spaced="right" src={entity.picture && entity.picture.uri} alt={entity.fullname || ''} />
              <Header.Content>
                {entity.fullname}
                <Header.Subheader>
                  {utils.handleError(err)}
                </Header.Subheader>
              </Header.Content>
            </Header>
          );
        })}
      </div>}
    />
  );

  renderInfosModal = () => this.props.store.appends.push(
    <BetaModal
      id="InfoModal"
      onClose={() => this.props.store.appends.pop()}
      size="large"
      closeOnBack
      style={{ width: 903, marginLeft: -451 }}
      header={
        <span>{__('Learn to Create Channels')}<Icon name="message smile" style={{ fontWeight: 300, marginLeft: 12 }} /></span>
      }
      content={
        <div>
          <div style={{ marginBottom: 20 }}>
            <p>{__('Watch the video and discover everything you can do in the Channels menu.')}</p>
            <p>
              {__('If you prefer, open it on Youtube to continue watching it while using it. ')}
              <a href="https://youtu.be/wu9ZzkxCsBw" target="_blank" rel="noopener noreferrer" style={{ color: '#094fff' }}>
                {__('Youtube')}
                <Icon name="square arrow up right" />
              </a>
            </p>
          </div>
          <iframe
            style={{ borderRadius: 12 }}
            width="855"
            height="481"
            src="https://www.youtube.com/embed/wu9ZzkxCsBw"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
            allowFullScreen
          />
        </div>
      }
    />
  );

  updateEntities = (values) => {
    const { entities, name, invite } = values;
    let data = [];
    let message = '';

    if (name === 'delete') {
      data = entities.map(id => ({ id }));
      message = entities.length > 1 ? __('Channels were deleted successfully') : __('Channel was deleted successfully');
    } else if (name === 'disable') {
      data = entities.map(id => ({ id, disabled: true }));
      message = entities.length > 1 ? __('Channels were disabled successfully') : __('Channel was disabled successfully');
    } else if (name === 'enable') {
      data = entities.map(id => ({ id, disabled: false }));
      message = entities.length > 1 ? __('Channels were enabled successfully') : __('Channel was enabled successfully');
    } else if (name === 'invite') {
      data = entities.map(id => ({ id, invite }));
      message = entities.length > 1 ? __('Channels were invited successfully') : __('Channel was invited successfully');
    } else if (name === 'roles') {
      data = values.entities.map(entity_id => ({ id: entity_id, groups: values.groups, attach: values.attach ? ['groups'] : [] }));
      message = __('Roles were changed successfully');
    } else if (name === 'transform') {
      data = values.entities.map(entity_id => ({ id: entity_id, invisible: true }));
      message = __('Channels were transformed successfully');
    }

    if (name === 'delete') {
      return this.props.api.patch('mutation', deleteChannel, data).then(async (data) => {
        await this.props.client.resetStore();
        this.props.store.appends.pop();
      }).catch(e => console.error(e));
    }

    return this.props.api.patch('mutation', name === 'invite' ? inviteEntity : updateEntity, data).then(async (data) => {
      this.setState({ selectAll: false });

      await this.props.client.resetStore();

      this.props.store.appends.pop();

      const errors = data.reduce((prev, item) => prev.concat(item.errors ? item.errors.map(e => ({ id: e.path[0].replace('updateEntity_', ''), message: e.message })) : []), []);

      if (errors.length) {
        this.renderUpdateEntitiesError(errors);
      } else {
        this.props.store.snackbar = { active: true, message, success: true };
      }
    });
  }

  openRoleForm = (selectedEntities = [], entities) => {
    this.props.store.appends.push(
      <BetaModal
        id={'RoleAdd'}
        onClose={() => this.props.store.appends.pop()}
        size="small"
        actions={[
          <BetaButton
            data-action="cancel"
            floated="left"
            round
            transparent
            text={__('Cancel')}
            onClick={() => this.props.store.appends.pop()}
          />
        ]}
        header={__('Add channels to groups')}
        content={
          <RoleForm
            modal
            type="channel"
            multipleGroups
            values={{ entities: selectedEntities, name: 'roles' }}
            onSubmit={this.updateEntities}
            submitButton={__('Add')}
            entities={entities}
          />
        }
      />
    );
  }

  onAddFilter = async (value, key) => {
    this.setState({ loading: true });
    this.setState((prevState) => {
      if (value === 'clear-all') {
        prevState[key] = prevState[key].map(x => ({ ...x, selected: false }));
        return prevState;
      }
      const index = prevState[key].findIndex(x => x.value === value);
      prevState[key][index].selected = true;
      return prevState;
    }, async () => {
      const allIds = this.state[key].map(i => i.selected && i.value).filter(i => i);
      const ids = this.state[key].map(i => i.selected && i.value).filter(i => i && i !== 'noGroup' && i !== 'seeAll');
      if (key === 'groups') {
        const indexNoGroup = allIds.indexOf('noGroup');
        if (indexNoGroup > -1) {
          await this.onUniqueParameterChange('noGroup', 'true');
        } else {
          await this.onUniqueParameterChange('noGroup', 'false');
        }
        const indexSeeAll = allIds.indexOf('seeAll');
        if (indexSeeAll > -1) {
          await this.onUniqueParameterChange('seeAll', 'true');
        } else {
          await this.onUniqueParameterChange('seeAll', 'false');
        }
        this.onMultipleParameterChange('groupsIds', ids);
      }
    }
    );
    this.setState({ loading: false });
  }

  onDeleteFilter = (filter) => {
    this.setState({ loading: true });
    const value = filter.value;
    const key = filter.key;
    if (filter.multipleTags) {
      this.onDelete(value, key);
      return null;
    }

    this.setState((prevState) => {
      const index = prevState[key].findIndex(x => x.value === value);
      prevState[key][index].selected = false;
      return prevState;
    }, async () => {
      const allIds = this.state[key].map(i => i.selected && i.value).filter(i => i);
      const ids = this.state[key].map(i => i.selected && i.value).filter(i => i && i !== 'noGroup' && i !== 'seeAll');
      if (key === 'groups') {
        const indexNoGroup = allIds.indexOf('noGroup');
        if (indexNoGroup > -1) {
          await this.onUniqueParameterChange('noGroup', 'true');
        } else {
          await this.onUniqueParameterChange('noGroup', 'false');
        }
        const indexSeeAll = allIds.indexOf('seeAll');
        if (indexSeeAll > -1) {
          await this.onUniqueParameterChange('seeAll', 'true');
        } else {
          await this.onUniqueParameterChange('seeAll', 'false');
        }
        this.onMultipleParameterChange('groupsIds', ids);
      }
    }
    );
    this.setState({ loading: false });
  }

  /**
  * @params (required) object with informations about filter {
 *     filterBy: string, that indicate the filter item selected
 *     key: string, that indicate what filter is
 *   }
  **/
  simpleFiltersCallback = (params) => {
    const keys = {
      status: 'status',
      order: 'orderBy',
      groups: 'groups'
    };
    let value = params.filterBy;
    const key = params.key;
    if (params.multipleTags) {
      this.onAddFilter(value, key);
      return null;
    }

    this.setState({ loading: true });
    if (value === 'clear-all') {
      this.onUniqueParameterChange(keys[key], '');
      this.setState((prevState) => {
        prevState[key] = prevState[key].map(x => ({ ...x, selected: false }));
        return prevState;
      });
    } else {
      this.onUniqueParameterChange(keys[key], value);
      this.setState((prevState) => {
        if (key != 'order') {
          const index = prevState[key].findIndex(x => x.value === value);
          prevState[key] = prevState[key].map(x => ({ ...x, selected: false }));
          prevState[key][index].selected = true;
        }
        return prevState;
      });
    }

    this.setState({ loading: false });
  }

  onChangeText = (value) => {
    clearTimeout(this.lastRequestId);
    let id;
    this.lastRequestId = id = setTimeout(() => this.onUniqueParameterChange('search', value), 600);
  }

  menuDropDownCallback = (value) => {
    const { router, store, location } = this.props;
    const query = { ...location.query };
    const lang = store.app.locale ? (store.app.locale === 'pt-BR' ? 'pt' : store.app.locale) : 'en';
    const exportLink = `${store.app.url}/csv/organization/entities?` +
      `organization_id=${store.currentOrganization.id}&access_token=${encodeURIComponent(store.access_token)}${query.limit ? '' : '&limit=40000'}&tz_offset=${-(new Date().getTimezoneOffset())}&locale=${lang}&${utils.stringify(query)}`;
    switch (value) {
      case 'import-student':
        router.push(`/organizations/${store.currentOrganization.id}/entities/import/student`);
        break;
      case 'import-staff':
        router.push(`/organizations/${store.currentOrganization.id}/entities/import/staff`);
        break;
      case 'send-invites':
        router.push(`/organizations/${store.currentOrganization.id}/invitations`);
        break;
      case 'email-invite':
        router.push(`/organizations/${store.currentOrganization.id}/invitations?type=EMAIL`);
        break;
      case 'phone-invite':
        router.push(`/organizations/${store.currentOrganization.id}/invitations?type=PHONE`);
        break;
      case 'export':
        window.location.replace(exportLink);
        break;
      default:
        break;
    }
  }

  addChannel = () => {
    const { router, store } = this.props;
    router.push(`/organizations/${store.currentOrganization.id}/channels/add`);
    analytics.sendGoogleAnalyticsEvent(
      {
        name: 'Channels',
        category: 'add_channel',
        label: `OrganizationId: ${store?.currentOrganization?.id} | EntityId: ${store?.currentEntity?.id}`,
      },
      { store },
    );
  }

  renderTable = ({ rows, loading, data, pagination }) => {
    const { store, location } = this.props;
    const selectedEntities = data.filter(e => e.selected);
    const isAllSelectedEntitiesDisabled = every(selectedEntities, ['disabled', true]);
    const isFreeOrganization = store.currentOrganization && store.currentOrganization.plan === 'FREE';

    const hasPermission = ((store.currentEntity && store.currentEntity.type === 'ADMIN') ||
      (store.currentEntity && store.currentEntity.organization && store.currentEntity.organization.permissions &&
        (store.currentEntity.organization.permissions.hasAdmin || store.currentEntity.organization.permissions.entityScopes.includes('CREATE_UPDATE_ENTITY'))));

    const simpleFiltersList = [
      { name: __('Sort'), key: 'order', removeButton: false, icon: 'arrow down arrow up' },
      { name: __('Status'), key: 'status', removeButton: true, icon: 'chevron down' },
      { name: __('Group'), key: 'groups', removeButton: true, multipleTags: true, searchable: true, style: { width: 105 }, icon: 'chevron down' },

    ];

    const simpleFilters = simpleFiltersList.map(({ key, name, removeButton, multipleTags, searchable, style, icon }) => ({
      removeButton,
      key,
      multipleTags,
      searchable,
      style,
      icon,
      placeholder: name,
      options: this.state[key],
      fn: value => this.simpleFiltersCallback(value),
      onDelete: this.onDeleteFilter
    }));

    let menuButton;
    if (this.isMobile()) {
      menuButton = { icon: 'plus', fn: () => this.addChannel() };
    } else {
      menuButton = { name: __('Add channel'), icon: 'plus', fn: () => this.addChannel() };
    }

    const menu = [];
    if (hasPermission) {
      menu.push({ type: 'button', params: menuButton });
    }

    let selectedMenu = [];
    if (hasPermission) {
      selectedMenu.push({
        name: isAllSelectedEntitiesDisabled ? __('Enable channels') : __('Disable channels'),
        icon: isAllSelectedEntitiesDisabled ? 'user' : 'user slash',
        fn: () => this.confirmModal(selectedEntities, isAllSelectedEntitiesDisabled ? 'enable' : 'disable')
      });
      selectedMenu.push({ name: __('Invite users'), icon: 'user plus', fn: () => this.confirmModal(selectedEntities, 'invite') });
      selectedMenu.push({ name: __('Add channels to groups'), icon: 'folder user', fn: () => this.openRoleForm(selectedEntities.map(e => e.id), data) });
      selectedMenu.push({ name: __('Tranform into people'), icon: 'exchange alt', fn: () => this.confirmModal(selectedEntities, 'transform') });
    }

    if (store?.currentUser?.isMaster) {
      selectedMenu.push({ name: __('Delete'), icon: 'trash', fn: () => this.confirmModal(selectedEntities, 'delete') });
    }
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 40, alignItems: 'center' }}>
          <h2 style={{ paddingLeft: 8, fontSize: '2.29rem', lineHeight: '38px', paddingBottom: '3px' }}>{__('Service channels')}</h2>
          {
            !this.isMobile() && isFreeOrganization &&
            <div
              style={{ cursor: 'pointer', marginLeft: '11px', display: 'flex' }}
              onClick={() => this.renderInfosModal()}
            >
              <Icon style={{ fontWeight: 300, color: 'rgba(0, 0, 0, 0.24)', fontSize: '20px' }} name="play circle" />
              <span style={{ fontSize: '16px', color: 'rgba(0, 0, 0, 0.4)' }}>{__('See how to use')}</span>
            </div>
          }
        </div>
        <Table
          colNames={[...this.colNames, '']}
          selectColumn
          withSelection={!this.isMobile()}
          selectedCount={!loading && selectedEntities.length}
          onSelectAll={() => this.onSelectAll()}
          selectAll={this.state.selectAll}
          onEntitySelect={entity_id => this.onEntitySelect(entity_id)}
          data={data}
          rows={rows}
          search={{ placeholder: __('Search...'), api: { fn: value => this.onChangeText(value) }, fillAllWidth: this.isMobile() }}
          simpleFilters={this.isMobile() ? null : simpleFilters}
          loading={loading}
          gridColumns={this.isMobile() ? '8fr 0.5fr' : '0.5fr 7fr 7fr 4fr 4fr 3fr 3fr 0.5fr'}
          menu={menu}
          selectedMenu={selectedMenu}
          location={location}
          type={'channel'}
        />
        <Paginator
          isMobile={this.isMobile}
          source={data}
          pagination={pagination}
          onLoadMore={this.eventReload}
        />
      </div>
    );
  };

  renderEntityFullname = ({ to, fullname, style, disabledIcon }) => {
    const isAdmin = this.props.store.currentEntity && this.props.store.currentEntity.type === 'ADMIN';

    if (isAdmin) return <Link to={to} style={{ color: '#084FFF' }}>{fullname}{disabledIcon && <Icon style={{ color: 'rgba(0, 0, 0, 0.6)', marginLeft: '0.5rem' }} name="user slash" />}</Link>;

    return <span style={style}>{fullname}</span>;
  }

  renderFullname = (entity) => {
    return (
      <div style={{ display: 'flex', height: '50px' }}>
        <div style={{ display: 'flex', width: '50px', height: '100%', alignItems: 'center' }}>
          <Avatar style={{ width: '40px', height: '40px' }} avatar src={entity.picture && entity.picture.uri} alt={entity.fullname || ''} verticalAlign="middle" />
        </div>

        <div style={{ paddingLeft: '1rem', display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'center' }}>
          {entity.fullname.length > 18 ?
            <Popup
              trigger={
                this.renderEntityFullname({
                  to: `/entities/${entity.id}`,
                  fullname: entity.fullname.substring(0, !entity.disabled ? 18 : 14).trim() + '...',
                  style: {
                    display: 'inline-block',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '70%',
                  },
                  disabledIcon: entity.disabled
                })
              }
              content={entity.fullname}
            />
            :
            this.renderEntityFullname({
              to: `/entities/${entity.id}`,
              fullname: entity.fullname,
              disabledIcon: entity.disabled
            })
          }
          {entity.type === 'ADMIN' &&
            <span style={{ fontSize: 13, color: 'rgba(0, 0, 0, 0.6)' }}>{__('Admin')}</span>
          }
        </div>
      </div>
    );
  }

  renderEditButton = (entity) => {
    const { router, store } = this.props;
    const onClick = (store) => {
      router.push(`/organizations/${store.currentOrganization.id}/channels/${entity.id}/edit`)
      analytics.sendGoogleAnalyticsEvent(
        {
          name: 'Channels',
          category: 'edit_channel',
          label: `OrganizationId: ${store?.currentOrganization?.id} | EntityId: ${store?.currentEntity?.id}`,
        },
        { store },
      );
    }

    return (
      !((store.currentEntity && store.currentEntity.type === 'ADMIN') || (store.currentEntity && store.currentEntity.organization && store.currentEntity.organization.permissions && (store.currentEntity.organization.permissions.hasAdmin || (store.currentEntity.organization.permissions.entityScopes.includes('CREATE_UPDATE_ENTITY') && entity.type !== 'ADMIN')))) ?
        null
        :
        <div id={`edit_channel_${store.currentOrganization.id}_${entity.id}`} style={{ display: 'flex', alignContent: 'center', cursor: 'pointer' }} onClick={() => onClick(store)}>
          <Icon name="pencil" style={{ fontWeight: 300 }} />
        </div>
    );
  }
  renderGroups = (entity) => {
    const { roles } = entity;
    let allGroups;
    if (entity.seeAll) {
      allGroups = [{ name: __('All') }, ...roles.nodes];
    } else {
      allGroups = [...roles.nodes];
    }
    if (allGroups.length > 0) {
      return (
        <Popup
          trigger={<div>{`${(allGroups[0].name.length > 16 ? (allGroups[0].name.substring(0, 16) + '...') : allGroups[0].name)}${allGroups.length > 1 ? ` +${allGroups.length - 1}` : ''}`}</div>}
          content={<span>{allGroups.map((g, i) => (i == allGroups.length - 1 ? `${g.name}` : `${g.name}, `))}</span>}
        />);
    }
    return '-';
  }

  renderDescription = description => (
    <div style={{ display: 'flex', alignItems: 'center', height: '50px' }}>
      {description ?
        (
          description.length > 25 ?
            <Popup
              trigger={<span style={{ display: 'inline-block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '90%' }}>
                {description.substring(0, 25).trim() + '...'}
              </span>}
              content={<span>{description}</span>}
            />
            :
            <span>
              {description}
            </span>
        )
        :
        <span>
          {'-'}
        </span>
      }
    </div>
  )

  renderChannelType = confAttendanceEnabled => (
    <div style={{ display: 'flex', alignItems: 'center', height: '50px' }}>
      <span>
        {confAttendanceEnabled ? __('Attendance status') : __('Simple conversation')}
      </span>
    </div>
  )

  renderStatus = (entity) => {
    if (entity.disabled) {
      return __('Disabled');
    }
    if (entity.users.totalCount > 0) {
      return __('Registered');
    }
    if (entity.invitedAddresses.totalCount > 0 && entity.users.totalCount == 0) {
      __('Invited');
    }
    if (entity.users.totalCount == 0) {
      return __('Not registered');
    }
    if (entity.addresses.totalCount == 0) {
      return __('No addresses');
    }
  }

  setGroupsFilters = () => {
    const { location } = this.props;
    const query = { ...location.query };
    let groupsIds = [];
    if (query.groupsIds) {
      groupsIds = JSON.parse(query.groupsIds);
    }
    let groups = [{ value: 'noGroup', text: __('No group'), selected: query.noGroup ? JSON.parse(query.noGroup) : false }, { value: 'seeAll', text: __('All groups'), selected: query.seeAll ? JSON.parse(query.seeAll) : false }];
    groups = [...groups, ...this.props.data.node.groups.nodes.map(g => ({ value: g.id, text: g.name, selected: groupsIds.includes(g.id) }))];
    this.setState({ groups });
  }
  renderUsers = (entity) => {
    const onClick = (store) => {
      analytics.sendGoogleAnalyticsEvent(
        {
          name: 'Channels',
          category: 'go_to_users',
          label: `OrganizationId: ${store?.currentOrganization?.id} | EntityId: ${store?.currentEntity?.id}`,
        },
        { store },
      );
    }

    const { store } = this.props;
    const isMaster = store?.currentUser?.isMaster;
    if (utils.hasEntityInCurrentOrganization(store, ['ADMIN']) || isMaster) {
      return (
        <Link
          className={'hoverLink'}
          to={`/entities/${entity.id}/accounts`}
          onClick={() => onClick(store)}
          style={{
            color: '#000000',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '40px',
            width: '40px',
            borderRadius: '100px'
          }}
        >
          {entity.users.totalCount}
        </Link>
      );
    }
    return (<div
      style={{
        color: '#000000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '40px',
        width: '40px',
      }}
    >{entity.users.totalCount}</div>);
  }

  renderRow = (entity, hasMenuConversations) => {
    if (hasMenuConversations) {
      return [
        { value: this.renderFullname(entity) }, { value: this.renderDescription(entity.description) }, { value: this.renderChannelType(entity.confAttendanceEnabled) },
        { value: this.renderGroups(entity) }, { value: this.renderUsers(entity) }, { value: this.renderStatus(entity) }, { value: this.renderEditButton(entity) }
      ]
    }

    return [
      { value: this.renderFullname(entity) }, { value: this.renderDescription(entity.description) },
      { value: this.renderGroups(entity) }, { value: this.renderUsers(entity) }, { value: this.renderStatus(entity) }, { value: this.renderEditButton(entity) }
    ]
  }

  render() {
    const { data, location, store } = this.props;
    if ((data.loading && !data.node) || !data.node) return <Loader active inline="centered" />;
    const entities = data.node.entities;
    const { nodes, totalCount } = entities;
    const query = { ...location.query };
    const hasMenuConversations = store?.currentOrganization?.unleashStatus?.web_menu_conversations && hasConversationProductFeature(store.currentOrganization);

    /* if (this.state.shouldShowConversationModal) {
      return (
        <ChatNews
         store={this.props.store}
         router={this.props.router}
         onClose={()=> this.setState({shouldShowConversationModal: false})} />
      )
    } */

    if (query.groupsIds) {
      query.groupsIds = JSON.parse(query.groupsIds);
    }

    if (query.noGroup) {
      query.noRole = query.noGroup;
      delete query.noGroup;
    }
    if (this.props.data.node.groups && this.props.data.node.groups.nodes && this.props.data.node.groups.nodes.length > 0 && this.state.groups.length === 0) {
      this.setGroupsFilters();
    }
    let preparedEntities;
    if (this.isMobile()) {
      preparedEntities = nodes.map(e => [{ value: this.renderFullname(e) }, { value: this.renderEditButton(e) }]);
    } else {
      preparedEntities = nodes.map(entity => this.renderRow(entity, hasMenuConversations));
    }

    return (
      <div id="OrganizationEntities">
        <div style={{ display: 'flex' }}>
          <div
            style={{
              width: '100%'
            }}
          >
            <div>
              {
                this.renderTable({
                  rows: preparedEntities,
                  loading: data.loading,
                  data: nodes,
                  pagination: {
                    loading: false,
                    totalCount,
                    query: location.query
                  }
                })
              }
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
