import React from 'react';
import { Link } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Grid, Loader, Divider, Container, Segment, Button, Message } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';

import TopCover from '../../components/TopCover';
import Footer from '../../components/Footer';

import { __ } from '../../i18n';

@inject('store')
@graphql(gql`query OrganizationPublicQuery (
  $username: String!
) {
    organization(username: $username) {
      fullname
      ... TopCover
    }
  }
  ${TopCover.fragments.organization}
`, {
  options: ownProps => ({
    variables: {
      username: ownProps.params.username
    },
    errorPolicy: 'ignore'
  })
})
@observer
export default class OrganizationPublic extends React.Component {

  UNSAFE_componentWillReceiveProps() {
    const { data, store } = this.props;

    if (!data.loading && !data.organization) {
      store.app.title = __('Organization Not Found');
    } else if (!data.loading) {
      store.app.title = data.organization.fullname;
    }
  }

  render() {
    const { data } = this.props;

    if (data.loading && !data.organization) return <Loader active inline="centered" />;

    if (!data.organization) {
      return (
        <div id="OrganizationPublic">
          <div style={{ height: '55px' }} />
          <Container style={{ marginTop: '40px' }}>
            <Grid stackable>
              <Grid.Column width={4} />
              <Grid.Column stretched width={8}>
                <Container textAlign="center">
                  <Message size="large" compact negative>
                    <Message.Header>{__('Error (404) - Organization Not Found')}</Message.Header>
                    <p>{__('The requested organization %s was not found.', this.props.params.username.replace('/notfound', ''))}</p>
                  </Message>
                  <Divider section hidden />
                  <Footer />
                </Container>
              </Grid.Column>
              <Grid.Column width={4} />
            </Grid>
          </Container>
        </div>
      );
    }

    return (
      <div id="OrganizationPublic">
        <div style={{ height: '55px' }} />
        <TopCover organization={filter(TopCover.fragments.organization, data.organization)} isAdmin={false} isPublic />
        <Container style={{ marginTop: '40px' }}>
          <Grid stackable>
            <Grid.Column width={1} />
            <Grid.Column stretched width={14}>
              <Container textAlign="center" >
                <Segment padded="very" style={{ fontSize: '1.1em' }}>

                  <h2>{__('Welcome to ClassApp!')}</h2>
                  <p>{__('ClassApp is the application that substitutes the school agenda, connecting schools, teachers, parents and students in a simple and efficient way.')}</p>
                  <p>{data.organization.fullname + ' ' + __('is on ClassApp, so if you are a staff, parent or student of this school, click on one of the buttons below:')}</p>

                  <Button.Group>
                    <Button primary as={Link} to={'/auth'}>{__('Sign up')}</Button>
                    <Button as={Link} to={'/auth'}>{__('Login')}</Button>
                  </Button.Group>
                </Segment>
                <Divider section hidden />
                <Footer />
              </Container>
            </Grid.Column>
            <Grid.Column width={1} />
          </Grid>
        </Container>
      </div>
    );
  }
}
