/*eslint prefer-const: off*/
import React from 'react';
import { Button, Image, Icon, Grid, Popup, Accordion } from 'semantic-ui-react';
import { Link } from 'react-router';
import { __ } from '../../i18n';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import * as utils from '../../utils';
import publicIp from 'react-public-ip';

const styles = {
  mainPanel: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '50px'
  },
  mainText: {
    fontSize: '18px',
    lineHeight: '21px',
    textTransform: 'uppercase',
    color: '#0069FF',
    marginBottom: '15px',
  },
  faqTextStyle: {
    lineHeight: '140%',
    fontSize: '16px'
  }
};
@inject('store')
@graphql(gql`mutation updateOrganizationPayTerms($updateOrganizationMutation: UpdateOrganizationInput!) {
  updateOrganization(input: $updateOrganizationMutation) {
    clientMutationId
  }
}`, {
  name: 'updatePayTerms',
  options: {
  refetchQueries: ['OrganizationNodeUsernameQuery', 'OrganizationNodeIdQuery']
  }
  })
@observer
export default class OrganizationPaymentTerms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: null
    };

    this.sections = [
      {
        position: 'left',
        image: utils.asset('/classpay/payments_mockup.png'),
        title: __('Quickly create a payment request'),
        gifts: [
          __('Resolve financial issues by creating a simple message to parents;'),
          __('Send the charges you want with a few clicks;'),
          {
            text: __('Choose which bank you want to receive payments from.'),
            description: __('For Bradesco bank accounts, the withdrawal is free. For other accounts from other banks, the fee for each withdrawal will be R$3.67.')
          }
        ]
      },
      {
        position: 'right',
        image: utils.asset('/classpay/payments.gif'),
        title: __('Freedom in choosing the payment method'),
        gifts: [
          {
            text: __('Possibility of payment by credit card or bank slip;'),
            description: <p>
              {__('For each payment made, there is a fee directed to the person responsible or absorbed by the institution:')}
              <br /> {__('- Bank slip: R$ 2,90')}
              <br /> {__('- Credit card: 2.9%% of the charge amount')}
              <br />{__('See more')}<a target="_blank" rel="noopener noreferrer" href="https://ajuda.classapp.com.br/hc/pt-br/articles/1260806167949-Existe-alguma-taxa-de-utiliza%C3%A7%C3%A3o-para-o-ClassPay-"> {__('here')}</a>
            </p>
          },
          __('Fast transaction and without leaving home;'),
          __('No queues at the treasury.'),
        ]
      },
      {
        position: 'left',
        image: utils.asset('/classpay/payment_notifications.png'),
        title: __('Excellent user experience'),
        gifts: [
          __('Intuitive and easy-to-use layout;'),
          __('Automatic reminders of billing due;'),
          __('95%% average join rate and high read rate.'),
        ]
      },
      {
        position: 'right',
        image: utils.asset('/classpay/payments_dashboard.jpg'),
        title: __('Follow in real time'),
        gifts: [
          __('Get extract and predictability of all added accounts.'),
          __('Analyze administrative dashboards with clear information about the status of each charge;'),
          __('Get an overview of payments received or receivable in a given period;')
        ]
      },
      {
        position: 'left',
        image: utils.asset('/classpay/payment_details.jpg'),
        title: __('Trust a secure environment for transactions'),
        gifts: [
          __('Safe and confidential environment;'),
          __('PCI Compliance Seal ensuring transaction security;'),
          __('Count on our support team for questions.')
        ],
        button: () => this.renderConfirmButton()
      }
    ];

    this.faq = [
      {
        title: __('What is ClassPay?'),
        content: (
          <span style={styles.faqTextStyle}>
            {__('It is the functionality within ClassApp in which parents can make payments for charges sent by the school in a simple, fast and secure way, and schools can track this information within ClassApp itself.')}
            <br /><br />
            {__('The available payment methods are bank transfer and credit card.')}
            <br /><br />
            {__('ClassPay is not a financial system, it is just a means of sending and receiving payments directly through ClassApp, combining the ease and practicality of the application with sending charges.')}
          </span>
        ),
      },
      {
        title: __('How much does it cost to add this role to my school`s ClassApp?'),
        content: (
          <span style={styles.faqTextStyle}>
            {__('ClassPay is included in all plans and has only for paid charges that may or may not be absorbed by the institution. In addition, there is a withdrawal fee for banks other than Bradesco. Check the values below:')}
            <br /><br />
            {__('')}
            {__('By credit card: 2.9%% of the charge amount.')}
            <br /><br />
            {__('Via bank slip: R$2.90 per paid slip.')}
            <br /><br />
            {__('Withdrawal: for Bradesco banks it is free, for other banks R$3.67 per withdrawal.')}
          </span>
        ),
      },
      {
        title: __('What is the deadline for the amounts paid to be available to the school?'),
        content: (
          <span style={styles.faqTextStyle}>
            {__('Payments will be processed and made available on a digital school account. The school may request the transfer of amounts to your bank account at any time and also set up automatic withdrawals. The deadline varies according to the payment method chosen by the parent/guardian:')}
            <br /><br />
            {`• ${__('Bank slip: 5 working days after payment;')}`}
            <br />
            {`• ${__('Credit Card: 31 days after payment (29 calendar days + 2 business days).')}`}
            <br /><br />
            {__('See more details')} <a target="_blank" rel="noopener noreferrer" href="https://ajuda.classapp.com.br/hc/pt-br/articles/1260803084710-Liquida%C3%A7%C3%A3o-de-Pagamentos-Quanto-tempo-leva-para-os-valores-ficarem-dispon%C3%ADveis-para-saque-"> {__('here')}</a>.
          </span>
        ),
      },
      {
        title: __('Which flags are accepted?'),
        content: (
          <span style={styles.faqTextStyle}>
            {__('The accepted brands are: Mastercard, Visa, Hipercard, Elo, American Express, Discover, JBC and Diners Club.')}
          </span>
        ),
      },
      {
        title: __('Is ClassPay secure?'),
        content: (
          <span style={styles.faqTextStyle}>
            {__('And yes, it`s safe!')} <br />
            {__('Security is guaranteed by the payment gateway solution that has the PCI Compliance Seal, granted to companies that follow international security norms and standards for financial transactions.')}
          </span>
        ),
      },
    ];
  }

  handleClick = (e, { index }) => {
    const { activeIndex } = this.state;
    this.setState({ activeIndex: index === activeIndex ? null : index });
  }

  renderFaq = (fa, i) => {
    const { activeIndex } = this.state;
    const isActive = activeIndex == i;

    return (
      <div style={{ marginTop: '10px', marginBottom: '10px' }}>
        <Accordion.Title
          active={isActive}
          index={i}
          onClick={this.handleClick}
          style={{ flexDirection: 'row', display: 'flex' }}
        >
          <p style={{ fontWeight: 'bold', fontSize: '18px' }}>{fa.title}</p>
          <Icon name={isActive ? 'angle up' : 'angle down'} style={{ fontSize: '24px', color: '#084FFF', right: '10%', position: 'absolute' }} />
        </Accordion.Title>
        <Accordion.Content active={isActive} >
          {fa.content}
        </Accordion.Content>
      </div>
    );
  }
  renderSection = (section) => {
    const imageComponent = (
      <Grid.Column style={{ justifyContent: 'center' }}>
        <Image src={section.image} size="medium" style={{ margin: 'auto', maxHeight: '400px', width: 'auto' }} />
      </Grid.Column>
    );

    return (
      <Grid.Row columns={2} style={{ height: '450px', margin: '20px 0px' }}>
        {section.position === 'left' && imageComponent}
        <Grid.Column style={{ alignItems: 'center', display: 'flex' }}>
          <div>
            <p style={{ fontSize: '24px', fontWeight: 'bold' }}>{section.title}</p>
            {section.gifts.map((gift) => {
              if (typeof gift === 'object') {
                return (
                  <div style={{ display: 'flex', flexDirection: 'row', margin: '10px 0' }}>
                    <Icon name="check" style={{ fontSize: '18px', color: '#084FFF' }} />
                    <p style={{ fontSize: '18px', marginLeft: '10px' }}>{gift.text}
                      <Popup
                        hoverable
                        trigger={<Icon name="info circle outline" size="medium" />}
                        content={gift.description}
                      />
                    </p>
                  </div>
                );
              }

              return (
                <div style={{ display: 'flex', flexDirection: 'row', margin: '10px 0' }}>
                  <Icon name="check" style={{ fontSize: '18px', color: '#084FFF' }} />
                  <p style={{ fontSize: '18px', marginLeft: '10px' }}>{gift}</p>
                </div>
              );
            })}
            {section.button && section.button()}
          </div>
        </Grid.Column>
        {section.position === 'right' && imageComponent}
      </Grid.Row>
    );
  }

  renderConfirmButton = () => {
    const { currentEntity: { organization: { id: organizationId, confTerms: currentConfTerms } } } = this.props.store;
    return (
      <Button
        style={{ backgroundColor: '#0BAE6D', color: 'white', width: '195px', borderRadius: '20px', margin: '15px 0px' }}
        content={__('Start Now')}
        onClick={async () => {
          // set confTerms value to true
          const finalTerms = currentConfTerms ? ['PAYMENT', ...currentConfTerms] : ['PAYMENT'];

          // get user data
          finalTerms.push(new Date().toISOString());
          finalTerms.push(this.props.store.currentUser.id);
          finalTerms.push(await publicIp.v4() || '');

          const request = {
            mutate: this.props.updatePayTerms,
            input: 'updateOrganizationMutation',
          };
          request.mutate({
            variables: {
              updateOrganizationMutation: { id: organizationId, confTerms: finalTerms }
            }
          }).catch((err) => {
            console.error('Error trying to update confTerms values.', err);
          });
        }}
        as={Link}
        to={`/organizations/${organizationId}/payments`}
      />
    );
  }

  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', padding: '40px', paddingRight: '0px', paddingTop: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <a
            href="https://ajuda.classapp.com.br/hc/pt-br/categories/1260801778450-ClassPay"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'inherit', display: 'flex', marginRight: '110px', height: '30px' }}
          >
            <Icon name="question circle outline" size="normal" />
            <span style={{ textDecoration: 'underline' }}>{__('Everything about classpay')}</span>
          </a>
        </div>
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column>
              <h2>{__('Get paid easily with ClassApp')}</h2>
              <p style={{ fontSize: '16px' }}>{__('Meet ClassPay, our new safe, simple and practical solution for your financial transactions')}</p>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 5 }}>
                  {this.renderConfirmButton()}
                  <p style={{ opacity: 0.7, fontSize: '12pt' }}>{__('*By clicking “Start now” you accept the')} <a target="blank" href="https://d21rkbthdtyql9.cloudfront.net/termo-classpay.pdf"> {__('Additive Terms of Service')}</a></p>
                </div>
                <div style={{ display: 'flex', flex: 2 }}>
                  <Image src={utils.asset('/classpay/badge-classpay.png')} size="small" style={{ maxHeight: '110px', width: 'auto' }} />
                </div>
              </div>
            </Grid.Column>

            <Grid.Column>
              <iframe
                width="425"
                height="230"
                src="https://www.youtube.com/embed/o1-fLFVkcIw?autoplay=1"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </Grid.Column>
          </Grid.Row>

          {this.sections.map(section => this.renderSection(section))}

          <div style={{ margin: '20px auto', textAlign: 'center' }}>
            <h2 style={{ color: '#084FFF', fontSize: '24px' }}>{__('So, do you still have any doubts?')}</h2>
            <p style={{ fontSize: '18px' }}>{__('Here we separate the main questions from managers by sections, check it out!')}</p>
          </div>

          <div style={{ minWidth: '580px', width: '80%', margin: 'auto' }}>
            <Accordion defaultActiveIndex={0}>
              {this.faq.map((fa, i) => this.renderFaq(fa, i))}
            </Accordion>
          </div>

        </Grid>
      </div>
    );
  }
}
