import React from 'react';
import { Dropdown } from 'semantic-ui-react';

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Page from '../../components/Page';


import { __ } from '../../i18n';

@graphql(gql`mutation changeConsultant($updateOrganizationMutation: UpdateOrganizationInput!) {
  updateOrganization(input: $updateOrganizationMutation) {
    clientMutationId
  }
}`, {
  name: 'changeConsultant',
  })

export default class ChangeConsultantDropdown extends Page {
  constructor(props) {
    super(props);
    const { organization, channels } = props;

    this.state = { organization, channels };
  }

  handleChangeConsultant(id, consultantId) {
    const request = {
      mutate: this.props.changeConsultant,
      input: 'updateOrganizationMutation',
      message: __('Consultant was Changed'),
    };
    request.mutate({
      variables: {
        updateOrganizationMutation: { id, consultantId }
      }
    }).then(() => {
      const data = this.state.organization;
      data.consultantId = consultantId;
      this.setState(data);
    });
  }

  render() {
    const { organization, channels } = this.state;
    const consultantId = (organization.consultantId) ? parseInt(organization.consultantId, 10) : null;

    return (
      <Dropdown
        options={channels}
        search
        value={consultantId}
        onChange={(e, { value }) => {
          this.handleChangeConsultant(organization.id, value);
        }}
      />

    );
  }
}
