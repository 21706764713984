import { limitOptions } from '../getLimitOptions';
import { __ } from '../../../../i18n';

describe('getLimitOptions', () => {
  it('should return limitOptions', () => {
    expect(limitOptions()).toEqual([
      // eslint-disable-next-line sonarjs/no-duplicate-string
      { text: __('Last %s days', 30), value: 30 },
      { text: __('Last %s days', 15), value: 15 },
      { text: __('Last %s days', 7), value: 7 },
    ]);
  });
});
