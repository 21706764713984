import { getTimeOptions } from '../getTimeOptions';

describe('given the getTimeOptions function', () => {
  it('should return the correct time options', () => {
    const result = getTimeOptions(true);
    expect(result).toEqual({
      hour: [
        { text: '07', value: '07' },
        { text: '08', value: '08' },
        { text: '09', value: '09' },
        { text: '10', value: '10' },
        { text: '11', value: '11' },
        { text: '12', value: '12' },
        { text: '13', value: '13' },
        { text: '14', value: '14' },
        { text: '15', value: '15' },
        { text: '16', value: '16' },
        { text: '17', value: '17' },
        { text: '18', value: '18' },
        { text: '19', value: '19' },
        { text: '20', value: '20' },
        { text: '21', value: '21' },
        { text: '22', value: '22' },
        { text: '23', value: '23' },
        { text: '00', value: '00' },
        { text: '01', value: '01' },
        { text: '02', value: '02' },
        { text: '03', value: '03' },
        { text: '04', value: '04' },
        { text: '05', value: '05' },
        { text: '06', value: '06' },
      ],
      minute: [
        { text: '00', value: '00' },
        { text: '05', value: '05' },
        { text: '10', value: '10' },
        { text: '15', value: '15' },
        { text: '20', value: '20' },
        { text: '25', value: '25' },
        { text: '30', value: '30' },
        { text: '35', value: '35' },
        { text: '40', value: '40' },
        { text: '45', value: '45' },
        { text: '50', value: '50' },
        { text: '55', value: '55' },
        { text: '59', value: '59' },
      ],
    });
  });

  it('should return the correct time options', () => {
    const result = getTimeOptions(false);
    expect(result).toEqual({
      hour: [
        { text: '07', value: '07' },
        { text: '08', value: '08' },
        { text: '09', value: '09' },
        { text: '10', value: '10' },
        { text: '11', value: '11' },
        { text: '12', value: '12' },
        { text: '13', value: '13' },
        { text: '14', value: '14' },
        { text: '15', value: '15' },
        { text: '16', value: '16' },
        { text: '17', value: '17' },
        { text: '18', value: '18' },
        { text: '19', value: '19' },
        { text: '20', value: '20' },
        { text: '21', value: '21' },
        { text: '22', value: '22' },
        { text: '23', value: '23' },
        { text: '00', value: '00' },
        { text: '01', value: '01' },
        { text: '02', value: '02' },
        { text: '03', value: '03' },
        { text: '04', value: '04' },
        { text: '05', value: '05' },
        { text: '06', value: '06' },
      ],
      minute: [
        { text: '00', value: '00' },
        { text: '05', value: '05' },
        { text: '10', value: '10' },
        { text: '15', value: '15' },
        { text: '20', value: '20' },
        { text: '25', value: '25' },
        { text: '30', value: '30' },
        { text: '35', value: '35' },
        { text: '40', value: '40' },
        { text: '45', value: '45' },
        { text: '50', value: '50' },
        { text: '55', value: '55' },
      ],
    });
  });
});
