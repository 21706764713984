import React from 'react';
import { Loader } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import Page from '../../components/Page';
import { recurrenceLinks, loginFrom } from './Constants';

@inject('store')
@observer
export default class Recurrence extends Page {
  componentDidMount() {
    const { store } = this.props;
    const url = `${loginFrom[store.app.env]}/oauth/authorize?client_id=${recurrenceLinks.authClientId}&redirect_uri=${recurrenceLinks.urlRedirect[store.app.env]}`;
    window.location.replace(url);
  }

  render() {
    return <Loader id="OrganizationEnrollment" active inline="centered" />;
  }
}
