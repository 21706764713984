import React from 'react';
import axios from 'axios';
import { Loader } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Page from '../../components/Page';

const enrollmentLinks = {
  authClientId: '554e5f8c43043255ef94cbfb8eff42397cba8561ff630a0a156a9ea655b4ec77',
  loginRedirect: {
    local: 'http://localhost:5600',
    stage: 'https://alex.classapp.ninja',
    production: 'https://matriculas.classapp.com.br',
    beta: 'https://matriculas.classapp.com.br',
    internal: 'https://alex-internal.classapp.ninja',
  },
  loginFrom: {
    local: 'http://localhost:5400',
    stage: 'http://classapp.ninja',
    beta: 'https://beta.classapp.com.br',
    internal: 'http://walle-internal.classapp.ninja',
    production: 'https://classapp.com.br'
  },
  api: {
    uri: {
      production: 'https://marty.classapp.co/graphql',
      beta: 'https://marty.classapp.co/graphql',
      stage: 'https://marty.classapp.ninja/graphql',
      internal: 'https://marty-internal.classapp.ninja/graphql',
      local: 'http://localhost:3100/graphql',
    }
  },
};

const applicationId = {
  local: 20,
  stage: 29,
  production: 158,
  beta: 158,
  internal: 29,
};

@inject('store')
@graphql(gql`query UserEnrollmentTokenQuery {
  viewer {
    id: dbId
    oauthProviders {
      nodes {
        accessToken
        application { id: dbId }
      }
    }
  }
}
`
)
@observer
export default class OrganizationsEnrollment extends Page {
  componentDidUpdate = async (prevProps) => {
    const { data, store } = this.props;

    if (prevProps.data.loading && data.viewer) {
      const enrollmentProvider = data.viewer.oauthProviders.nodes.filter(
        op => op.application.id === applicationId[store.app.env]
      );

      if (enrollmentProvider.length) {
        const { params } = this.props;

        try {
          const query = `query($organization_id: ID!){
            node(id: $organization_id) {
              ...on Organization { enrollmentSetup { status } }
            }
          }`;
          const variables = { organization_id: params.organization_id };
          const token = enrollmentProvider[0].accessToken;
          const response = await this.fetchMarty(query, variables, token);

          if (response.data.node.enrollmentSetup) {
            window.location.replace(`${enrollmentLinks.loginFrom[store.app.env]}/oauth/authorize?client_id=${enrollmentLinks.authClientId}&redirect_uri=${enrollmentLinks.loginRedirect[store.app.env]}`);
            return;
          }
        } catch (e) {
          console.error(e);
          window.location.replace('https://www.classapp.com.br/rematricula-online');
        }
      }

      window.location.replace('https://www.classapp.com.br/rematricula-online');
    }
  };

  martyInstance = (token = null) => {
    const { store } = this.props;
    return (
      axios.create({
        baseURL: `${enrollmentLinks.api.uri[store.app.env]}`,
        headers: token ? { Authorization: `Bearer ${token}` } : null
      }))
    ;
  }

  fetchMarty(query, variables, accessToken) {
    return this.martyInstance(accessToken).post('', { query, variables }).then(res => res.data);
  }

  render() {
    return <Loader id="OrganizationEnrollment" active inline="centered" />;
  }
}
