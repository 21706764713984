import React from 'react';
import { Link } from 'react-router';
import { Menu, Label, Icon, Header, Container } from 'semantic-ui-react';
import gql from 'graphql-tag';

import Avatar from '../../components/Avatar';

import { __ } from '../../i18n';

export default class OrganizationHeader extends React.Component {
  static fragments = {
    organization: gql`
      fragment OrganizationHeader on Organization {
        id: dbId
        fullname
        logo {
          uri
        }
      }`
  }

  render() {
    const { organization } = this.props;

    if (!organization) return null;

    const src = organization.logo && organization.logo.uri;

    return (
      <Menu id="OrganizationHeader" vertical fluid secondary style={{ justifyContent: 'center', marginTop: 0, textAlign: 'center' }}>
        <Menu.Item key={0} fitted>
          <Container
            className="hoverReveal"
            as={Link}
            to={`/organizations/${organization.id}/edit/logo`}
            style={{
              width: '140px',
              display: 'inline-block'
            }}
          >
            <div
              style={{
                height: '150px',
                width: '150px'
              }}
            >
              <Avatar size="small" src={src} alt={organization.fullname} rounded style={{ height: '150px', width: '150px' }} />
            </div>
            <div
              style={{
                height: '140px',
                width: '140px',
                backgroundColor: 'initial',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                opacity: !src ? 1 : undefined
              }}
            >
              <Label
                id="ChangeOrganizationLogo"
                style={{ margin: 10, opacity: '0.7' }}
                color="black"
                size="mini"
              >
                <Icon name="image" />
                {__('Change image')}
              </Label>
            </div>
          </Container>
        </Menu.Item>

        <Menu.Item key={1}>
          <Header as="h4">
            <span style={{ wordBreak: 'break-word' }}>{organization.fullname}</span>
          </Header>
        </Menu.Item>
        {
          this.props.children !== undefined && <Menu.Item key={4}>{this.props.children}</Menu.Item>
        }
      </Menu>
    );
  }
}
