import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Loader } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import ImageEditor from '../../components/ImageEditor';

import { __ } from '../../i18n';

const createLogo = organizationId => `mutation createLogo {
  createLogo(input: { organizationId: ${organizationId} }) {
    clientMutationId
  }
}`;

@graphql(gql`query OrganizationLogoQuery ($id: ID!) {
  node(id: $id) @connection(key: "Organization", filter: ["id"]) {
    ... on Organization {
      id: dbId
      logo {
        id: dbId
        uri
      }
    }
  }
}`, {
  options: ownProps => ({
    variables: {
      id: ownProps.params.organization_id
    }
  })
})
@graphql(gql`mutation deletLogo($deleteLogoMutation: DeleteLogoInput!) {
  deleteLogo(input: $deleteLogoMutation) {
    clientMutationId
  }
}`, {
  name: 'deleteLogo',
  options: {
    refetchQueries: ['EntityNodeQuery', 'OrganizationNodeQuery', 'AppQuery']
  }
})

@inject('store') @observer
export default class OrganizationLogo extends React.Component {
  backAndRefresh = () => {
    const { store, router } = this.props;


    this.props.data.refetch();

    if (store.history.length > 1) {
      router.goBack();
    } else {
      const link = store.previous.params.entity_id ? `/entities/${store.previous.params.entity_id}/messages` :
        (store.previous.params.organization_id ? `/organizations/${store.previous.params.organization_id}/messages` : '/');
      router.push(link);
    }
  }

  render() {
    const { data, deleteLogo } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Loader active />;

    const { id, logo } = data.node;

    return (
      <ImageEditor
        id="OrganizationLogo"
        header={__('Edit Logo')}
        backAndRefresh={() => this.backAndRefresh()}
        submitRequest={createLogo(id)}
        onSubmit={this.backAndRefresh}
        submitTarget={'logo'}
        deleteRequest={deleteLogo}
        onDelete={this.backAndRefresh}
        deleteParams={{ deleteLogoMutation: { organizationId: id } }}
        hasPicture={logo && logo.uri}
      />
    );
  }
}
