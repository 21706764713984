import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Table, Icon } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Modal from '../../components/Modal';

import { sendGoogleAnalyticsEvent } from '../../lib/analytics';
import { __ } from '../../i18n';
import * as utils from '../../utils';

@inject('store')
@graphql(gql`mutation updateOrganization($updateOrganizationMutation: UpdateOrganizationInput!) {
  updateOrganization(input: $updateOrganizationMutation) {
    clientMutationId
  }
}`)
@observer
export default class OrganizationInvitations extends React.Component {
  sendInvitations = () => {
    const { store } = this.props;
    const isMaster = store.currentUser && store.currentUser.isMaster;
    const organizationId = store && store.currentOrganization && store.currentOrganization.id;
    const userType = isMaster ? 'MASTER' : 'ADMIN';

    try {
      this.props.mutate({
        variables: {
          updateOrganizationMutation: {
            id: this.props.store.currentOrganization.id,
            link: this.props.store.currentOrganization.link && this.props.store.currentOrganization.link.search('://') === -1 ?
              'http://' + this.props.store.currentOrganization.link : this.props.store.currentOrganization.link,
            invite: this.props.location.query.type || 'LIVE'
          }
        }
      });
      sendGoogleAnalyticsEvent({
        name: 'Invites',
        category: `Invite Organization by ${userType}`,
        label: `OrganizationID: ${organizationId}`,
      }, { store, usageRestrictions: { ignoreMaster: false } });

      this.props.router.goBack();
      this.props.store.snackbar = { active: true, message: __('Invitations sent'), success: true };
    } catch (e) {
      this.props.store.snackbar = { active: true, message: __('Error sending invitations'), success: false };
    }
  }

  renderTable = (data, key) => {
    const lang = this.props.store.app.locale && this.props.store.app.locale.substring(0, 2);
    const date = new Date();
    date.setDate(date.getDate() + data.offset);

    return (
      <Table.Row key={key}>
        <Table.Cell key={0}>
          {
            data.type === 'Email/SMS' ?
              [
                <Icon name={'envelope'} />,
                __('E-mail'),
                ' + ',
                <Icon name={'mobile'} />,
                __('SMS')
              ]
              :
              [
                <Icon name={data.type === 'Email' ? 'envelope' : 'mobile'} />,
                data.type === 'Email' ? __('E-mail') : __('SMS')
              ]
          }
        </Table.Cell>
        <Table.Cell key={1}>
          {
            data.offset ?
              utils.simpleDate(date, true, null, lang)
              :
              __('Today')
          }
        </Table.Cell>
      </Table.Row>
    );
  }

  render() {
    const { location, router } = this.props;
    const data = [
      { type: 'Email/SMS', offset: 0 },
      { type: 'Email', offset: 2 },
      { type: 'SMS', offset: 5 },
      { type: 'Email', offset: 8 }
    ];
    let message;

    if (location.query.type) {
      message = location.query.type === 'EMAIL' ? __('Email invitations will be sent') : __('SMS invitations will be sent');
    }

    return (
      <Modal id="OrganizationInvitations" open closeIcon="times close" size="small" onClose={() => router.goBack()}>
        <Modal.Header>
          {__('Invite everyone to join ClassApp')}
        </Modal.Header>
        <Modal.Content>
          {
            message ?
              <p>{message}</p>
              :
              <Table celled style={{ width: '70%', margin: 'auto' }}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell collapsing>{__('Invite by')}</Table.HeaderCell>
                    <Table.HeaderCell collapsing>{__('Date')}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {data.map((row, i) => this.renderTable(row, i))}
                </Table.Body>
              </Table>
          }
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            content={__('Start invitations')}
            onClick={() => this.sendInvitations()}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
