/*eslint prefer-const: off*/
import React from 'react';
import { Tab, Icon } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';

import Button from '../../components/ui/Button';
import Modal from '../../components/ui/Modal';
import Page from '../../components/Page';

import { __ } from '../../i18n';

@inject('store') @observer
export default class OrganizationArrived extends Page {
  state = {
    reloadTab: 0
  }

  panes = [
    { menuItem: __('History'), value: 'history', index: 0 },
    { menuItem: __('Data'), value: 'data', index: 1 },
    { menuItem: __('Settings'), value: 'settings', index: 2 },
  ];

  UNSAFE_componentWillMount = () => {
    const { router, params, location, store } = this.props;

    const arrivedHasBeenEnabled = store.currentOrganization.arrivedConfiguration !== null;
    if (!arrivedHasBeenEnabled && !['intro', 'settings'].includes(location.pathname.split('/')[4])) router.push(`/organizations/${params.organization_id}/arrived/settings`);
  }

  onTabChange = (e, data) => {
    const { router, params, location, store } = this.props;
    const { reloadTab } = this.state;

    if (data.panes[data.activeIndex].value === location.pathname.split('/')[4]) {
      this.setState({ reloadTab: 1 - reloadTab });
    }

    const arrivedHasBeenEnabled = store.currentOrganization.arrivedConfiguration !== null;

    if (arrivedHasBeenEnabled || data.panes[data.activeIndex].value === 'settings') router.push(`/organizations/${params.organization_id}/arrived/${data.panes[data.activeIndex].value}`);
    else if (['history', 'data'].includes(data.panes[data.activeIndex].value)) {
      this.props.store.appends.push(
        <Modal
          id="ErrorModal"
          onClose={() => this.props.store.appends.pop()}
          actions={[
            <Button
              data-action="ok"
              round
              text={__('Ok')}
              style={{ width: '94px', height: '40px' }}
              onClick={() => this.props.store.appends.pop()}
            />
          ]}
          header={__('Incomplete configuration')}
          content={
            <div>
              <span style={{ fontSize: '1.143rem', fontWeight: 400 }}>{__('To use "Arrived!" it is necessary to inform the tags that will have access to the resource, indicate the responsible employees and create at least one gate')}</span>
            </div>
          }
        />
      );
    }
  }

  render() {
    const { reloadTab } = this.state;
    const { location } = this.props;
    return (
      <div id="OrganizationArrived">
        <div style={{ width: '300px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div className="ArrivedHeader" style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: this.isMobile() ? null : 40, alignItems: 'center' }}>
            <h1>{__('Arrived!')}</h1>
            <a
              href="https://ajuda.classapp.com.br/hc/pt-br/articles/4417315288475"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'gray', marginLeft: '14px', alignItems: 'center', display: 'flex' }}
            >
              <Icon style={{ fontWeight: 300, color: 'rgba(0, 0, 0, 0.24)', fontSize: '20px' }} name="question circle" />
            </a>
          </div>
        </div>
        {location.pathname.split('/')[4] !== 'intro' &&
          <div className="ArrivedHeader" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Tab
              className="ArrivedTab"
              menu={{ secondary: true, pointing: true, color: 'blue' }}
              panes={this.panes}
              onTabChange={this.onTabChange}
              activeIndex={this.panes.length > 0 && this.panes.find(pane => location.pathname.indexOf(pane.value) > -1).index}
              style={{
                margin: '0px 0px 24px 0px',
                borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
                width: '100%'
              }}
            />
          </div>}
        {React.cloneElement(this.props.children, { reloadTab })}
      </div >
    );
  }
}
