/**
 * @typedef {{
 * features: {
 *   messageManager: 'ADVANCED' | 'BASIC';
 *   moments: boolean;
 *   reports: boolean;
 *   api: boolean;
 *   charges: boolean;
 *   forms: boolean;
 *   links: boolean;
 *   files: boolean;
 * },
 * confCharge: any;
 * }} IGetOrganizationPages
 */

/**
 * @param {IGetOrganizationPages} params
 */

export function organizationPages({ confCharge, features = {} }) {
  return {
    READ_ENTITY: ['entities', 'channels'],
    READ_DASHBOARD: ['dashboard'],
    READ_GROUP: ['groups'],
    READ_MESSAGE: ['messages', 'moments', 'chats']
      .filter((f) => features.messageManager !== 'BASIC' || f !== 'messages')
      .filter((f) => features.moments || f !== 'moments'),
    READ_REPORT: features.reports ? ['reports'] : [],
    READ_INTEGRATION: features.api ? ['integrations'] : [],
    READ_PAYMENT: features.charges && confCharge ? ['payments', 'charges'] : [],
    READ_FORM: features.forms ? ['forms'] : [],
    READ_ACCOUNT: features.charges && confCharge ? ['accounts'] : [],
    READ_LINK: features.links ? ['accesses'] : [],
    UPDATE_ORGANIZATION: ['edit'],
    READ_FILES: features.files ? ['files'] : [],
  };
}
